import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArchiveImg } from 'src/shared/assets/img/archiv.svg';
import { Button } from 'src/shared/components/button';
import { Modal } from 'src/shared/components/modal/modal';

import styles from './move-to-planned-modal.module.scss';

type Props = {
  isOpened: boolean;
  isWarning: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const MoveToPlannedModal = observer(function MoveToPlannedModal({
  onCancel,
  onConfirm,
  isOpened,
  isWarning,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpened={isOpened} className={styles.modal} closeOnClickOutside={false}>
      <ArchiveImg className={styles.image} />
      <h2 className={styles.title}>{t('modals:MoveToPlannedModal.title')}</h2>

      <p className={styles.description}>
        {t(isWarning ? 'modals:MoveToPlannedModal.warning' : 'modals:MoveToPlannedModal.desc')}
      </p>

      <div className={styles.buttonsWrapper}>
        {isWarning ? (
          <Button variant="primary" onClick={onCancel} className={styles.button}>
            {t('common:Buttons.clearly')}
          </Button>
        ) : (
          <>
            <Button variant="secondary" onClick={onCancel} className={styles.button}>
              {t('common:Buttons.cancel')}
            </Button>
            <Button variant="primary" onClick={onConfirm} className={styles.button}>
              {t('common:Buttons.move')}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
});
