import { action, computed, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { TDictObject } from 'src/api/directories/types';
import { mapApproachStage } from 'src/api/new-well/serializers/approaches-serializers';
import { TApproachStageRaw, TApproachStagesList, TSerializedStage } from 'src/api/new-well/types';
import { Directories } from 'src/store/directories/directories.store';

import { TOption } from '../../types';

import { ApproachStage } from './approach-stage.entity';
import { Approach } from './approach.entity';

type TApproachStagesListData = {
  directories: Directories;
  stageTemplate: TApproachStagesList;
  savedStages?: TSerializedStage[];
  approach: Approach;
};

function processStageOptions(data: TDictObject[] | null): TOption[] {
  if (!data) return [];
  return data
    .filter((stage) => stage.status === 'ACTIVE')
    .map((stageType) => ({
      label: stageType.data.name.toString(),
      value: stageType.id,
    }));
}

export class ApproachStagesList {
  readonly directories: Directories;
  readonly approach: Approach;
  readonly attrName: string;
  readonly stageTypes: TDictObject[];
  readonly stageReference: TApproachStageRaw;

  @observable stageOptions: TOption[];
  @observable stages: ApproachStage[];
  @observable isRequired: boolean;

  constructor(data: TApproachStagesListData) {
    this.approach = data.approach;
    this.directories = data.directories;
    this.isRequired = data.stageTemplate.required;
    this.attrName = data.stageTemplate.attrName;
    this.stageReference = data.stageTemplate.stage;
    this.stageTypes = this.directories.getObject(this.stageReference.wellLifeCycleStageId.refObjectType) || [];
    this.stageOptions = processStageOptions(
      this.directories.getObject(this.stageReference.wellLifeCycleStageId.refObjectType)
    );
    this.stages = this.getSavedStages(data.savedStages) || [];

    makeObservable(this);
  }

  private getSavedStages = (rawSavedStages?: TSerializedStage[]): ApproachStage[] | undefined => {
    if (!rawSavedStages || !this.stageReference) return;
    const reference = this.stageReference;
    const stagesDirectoryValues = this.directories.getObject(this.stageReference.wellLifeCycleStageId.refObjectType);
    const savedStages: ApproachStage[] = [];

    rawSavedStages.forEach((savedStage) => {
      const stageDirValue = stagesDirectoryValues?.find((dirValue) => dirValue.id === savedStage.wellLifeCycleStageId);
      if (stageDirValue) {
        savedStages.push(
          mapApproachStage(
            {
              reference,
              wellLifeCycleStageId: stageDirValue,
              directories: this.directories,
              parentControl: this.approach,
            },
            savedStage
          )
        );
      }
    });

    return savedStages;
  };

  @action.bound
  removeStages(): void {
    this.setStages([]);
  }

  @action.bound
  createStageByData(rawStage: TSerializedStage): ApproachStage | void {
    const reference = this.stageReference;
    const stagesDirectoryValues = this.directories.getObject(this.stageReference.wellLifeCycleStageId.refObjectType);
    const stageDirValue = stagesDirectoryValues?.find((dirValue) => dirValue.id === rawStage.wellLifeCycleStageId);
    if (stageDirValue) {
      return mapApproachStage(
        {
          reference,
          wellLifeCycleStageId: stageDirValue,
          directories: this.directories,
          parentControl: this.approach,
        },
        rawStage
      );
    }
  }

  @computed
  get startDate(): Moment | null {
    return this.stages.at(0)?.dateField?.startDate || null;
  }

  @computed
  get endDate(): Moment | null {
    return this.stages.at(-1)?.dateField?.endDate || null;
  }

  getStageById = (id: number | string): ApproachStage | null => {
    return this.stages.find((stage) => stage.id === id) || null;
  };

  @action.bound
  setIsVisuallyDisabled(is: boolean): void {
    this.stages.forEach((stage) => stage.setIsVisuallyDisabled(is));
  }

  @action.bound
  setStages(stages: ApproachStage[]): void {
    this.stages = stages;
  }

  @action.bound
  setStagesByData(stages: TSerializedStage[]): void {
    if (!this.stageReference) {
      return;
    }

    const stageInstances = this.getSavedStages(stages);

    if (stageInstances) {
      this.stages = stageInstances;
    }
  }

  @action.bound
  setStagesAndSwapDates(
    stages: ApproachStage[],
    activeStageIndex: number,
    overStageIndex: number,
    activeStageStartDate: Moment | null,
    overStageStartDate: Moment | null
  ): void {
    const firstStage = stages[activeStageIndex];
    const secondStage = stages[overStageIndex];

    firstStage.dateField?.setStartDate(activeStageStartDate);
    secondStage.dateField?.setStartDate(overStageStartDate);

    this.setStages(stages);
  }

  @action.bound
  changeStageType(stage: ApproachStage, newType: string | number): void {
    if (!this.stageReference) return;

    const reference = this.stageReference;
    const fieldValues: { name: string; value: unknown }[] = [];

    stage.fieldsList.forEach((field) => {
      if (field.value !== undefined) {
        fieldValues.push({
          name: field.fieldId,
          value: field.value,
        });
      }
    });

    const wellLifeCycleStageId = this.stageTypes?.find((type) => type.id === newType);

    if (!wellLifeCycleStageId) return;

    const newStage = mapApproachStage({
      reference,
      fieldValues,
      wellLifeCycleStageId,
      parentControl: this.approach,
      directories: this.directories,
    });
    const oldStageIndex = this.stages.findIndex((item) => item.id === stage.id);
    this.stages.splice(oldStageIndex, 1, newStage);
  }

  @action.bound
  addNewStage(stageTypeId: string | number): void {
    if (!this.stageReference) return;

    const wellLifeCycleStageId = this.stageTypes?.find((type) => type.id === stageTypeId);

    if (!wellLifeCycleStageId) return;

    this.stages.push(
      mapApproachStage({
        reference: this.stageReference,
        wellLifeCycleStageId,
        parentControl: this.approach,
        directories: this.directories,
      })
    );
  }

  @action.bound
  deleteStage(id: number): void {
    const targetStage = this.stages.find((stage) => stage.id === id);

    targetStage?.onDeleteCallbacks.forEach((clb) => clb());

    this.stages = this.stages.filter((item) => item.id !== id);
  }

  @action.bound
  validate(): void {
    this.stages.forEach((stage) => stage.validate());
  }
}
