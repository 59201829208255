import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { getDraftTitle } from 'src/features/plan-version-sidebar/utils';
import { ReactComponent as DeleteIcon } from 'src/shared/assets/icons/delete.svg';
import { PlanVersion } from 'src/store/comparison/types';

import styles from './drafts-list-item.module.scss';

interface Props {
  version: PlanVersion;
  onDeleteDraftVersion(draftVersion: PlanVersion): void;
  onVersionChange(draftVersion: PlanVersion): void;
}

export const DraftsListItem = observer(function DraftsListItem({
  version,
  onDeleteDraftVersion,
  onVersionChange,
}: Props) {
  const { t } = useTranslation();

  function onDeleteDraft(event: React.MouseEvent): void {
    onDeleteDraftVersion(version);
  }

  function onChangeDraft(): void {
    onVersionChange(version);
  }

  return (
    <li className={styles.listItem}>
      <button className={styles.listItemButton} onClick={onDeleteDraft}>
        <DeleteIcon className={styles.listItemButtonIcon} />
      </button>
      <p className={styles.listItemText} onClick={onChangeDraft}>
        {getDraftTitle(version, t)}
      </p>
    </li>
  );
});
