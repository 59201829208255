import { Approach } from 'src/features/well-form/entities/approach-entities';
import { Directories } from 'src/store/directories/directories.store';

import {
  TApproachRaw,
  TApproachStageRaw,
  TItemRaw,
  TSavedFormItems,
  TSerializedApproach,
  TSerializedStage,
} from '../../types';
import { mapItems } from '../common-serializers';

type Options = {
  approachReference: TApproachRaw;
  directories: Directories;
};

export const mapApproach = (
  { approachReference, directories }: Options,
  savedApproach?: TSerializedApproach
): Approach => {
  const savedFields: TSavedFormItems = {};
  let savedStages: TSerializedStage[] | undefined = undefined;

  const fields: TItemRaw[] = [];
  const stageReference: TApproachStageRaw | undefined = approachReference.stageTemplate.stage;

  for (let item of approachReference.items) {
    fields.push(item);
  }

  for (let key in savedApproach) {
    if (!key.includes('stages')) {
      savedFields[key] = savedApproach[key];
    }
    if (key.includes('stages')) {
      const stages = savedApproach[key];
      if (Array.isArray(stages)) {
        savedStages = stages;
      }
    }
  }

  const data = {
    fields: mapItems(fields, directories, savedFields),
    stageReference,
    approachReference,
    savedStages,
    directories,
    indexAttrName: approachReference.indexAttrName,
    rigOperationIdAttrName: approachReference.rigOperationIdAttrName,
  };

  return new Approach(data);
};
