import { action, autorun, makeObservable } from 'mobx';

import { RootStore } from 'src/store';

import { Item } from '../../../../shared/entities/abstract-control-entities';
import { PassingIntervalField } from '../../../../shared/entities/control-entities';
import { FormStore } from '../../entities/form.entity';
import { FormPlugin } from '../abstract-form-plugin.entity';

type TTotalDurationAndTotalPassingValue = {
  duration: number;
  passingValue: number;
};

export class SpeedPerDayFormulaPlugin extends FormPlugin {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item.fieldId === 'speedPerDay') {
        const approachesList = form.approachesTab?.approachesList;
        if (!!approachesList) {
          const controllingControl = form.fields['sectionsDetailing'];

          const disposer = autorun(() => {
            if (!controllingControl || !controllingControl.value) {
              if (item.value === 0) {
                item.setValue(null);
              }

              return;
            }
            const speedPerDayValues: TTotalDurationAndTotalPassingValue = approachesList.approaches.reduce(
              (prevAppr, curAppr) => {
                const approachValues: TTotalDurationAndTotalPassingValue = curAppr.stagesList.stages.reduce(
                  (prevStage, currStage) => {
                    const stageValues: TTotalDurationAndTotalPassingValue = currStage.sectionsList.sections.reduce(
                      (prevSec, currSec) => {
                        const dateField = currSec.dateField;
                        const passingField: PassingIntervalField | undefined = currSec.fieldsList.find(
                          (field): field is PassingIntervalField => field instanceof PassingIntervalField
                        );
                        const passingFieldValue = passingField?.passingValue;
                        if (!dateField || !passingField) {
                          return {
                            duration: prevSec.duration,
                            passingValue: prevSec.passingValue,
                          };
                        }
                        return {
                          duration: prevSec.duration + (dateField.duration ?? 0),
                          passingValue: prevSec.passingValue + (passingFieldValue || 0),
                        };
                      },
                      {
                        duration: 0,
                        passingValue: 0,
                      }
                    );

                    return {
                      duration: prevStage.duration + stageValues.duration,
                      passingValue: prevStage.passingValue + stageValues.passingValue,
                    };
                  },
                  {
                    duration: 0,
                    passingValue: 0,
                  }
                );

                return {
                  duration: prevAppr.duration + approachValues.duration,
                  passingValue: prevAppr.passingValue + approachValues.passingValue,
                };
              },
              {
                duration: 0,
                passingValue: 0,
              }
            );

            const speedPerDay =
              speedPerDayValues.duration !== 0 && speedPerDayValues.passingValue !== 0
                ? (1000 * speedPerDayValues.duration) / speedPerDayValues.passingValue
                : 0;
            item.tryToSetRawValue(speedPerDay || 0);
          });
          disposers.push(disposer);
        }
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
