import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { Loader } from 'src/shared/components/loader';
import { MainLayout } from 'src/shared/layouts/main-layout/main-layout';
import { useStore } from 'src/store';

import { DrillingCarpetHeader } from './components/drilling-carpet-header/drilling-carpet-header';
import { EmptyList } from './components/empty-list/empty-list';

import styles from './drilling-carpet-page.module.scss';

export const DrillingCarpetPage: React.FC = observer(function DrillingCarpetPage() {
  const { t } = useTranslation('drillingCarpet');

  const { planVersion, comparison, editing } = useStore();

  const isLoading = planVersion.isLoading;
  const isOverlapingPreloaderShown =
    comparison.isLoading || (editing.isLoading && !editing.isEditPublicDraftModalOpened);
  const haveData = !isLoading && planVersion.version !== undefined;

  const renderContent = () => {
    if (isLoading) {
      return <Spin size="large" />;
    }

    if (haveData) {
      return <Outlet />;
    }

    return <EmptyList />;
  };

  return (
    <MainLayout title={t('Title')} headerCustomComponent={<DrillingCarpetHeader />}>
      {renderContent()}
      {isOverlapingPreloaderShown && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </MainLayout>
  );
});
