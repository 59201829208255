import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { PadSidebar } from 'src/api/pad-info-sidebar/pad-sidebar-api';
import { Loader } from 'src/shared/components/loader';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { SwitchButtonGroup } from 'src/shared/components/switch-button-group';
import { hasValue } from 'src/shared/utils/common';
import { useStore } from 'src/store';

import { InfoSidebarTabContent } from '../graph-info-sidebar/info-sidebar-tab-component/info-sidebar-tab-component';

import { PadInfoSidebarStore, PadSidebarStore } from './pad-info-sidebar.store';

import styles from './pad-info-sidebar.module.scss';

interface Props {
  padId: number;
  rigId: number;
  isOpen: boolean;
  onClose: VoidFunction;
  onWellClick(wellId: number): void;
  onWellEditingClick?(wellId: number): void;
  onWellAdd?: VoidFunction;
  onChange?: PadSidebarStore.ChangeHandler;
  disableEditing?: boolean;
}

export const PadInfoSidebar = observer(function PadInfoSidebar({
  isOpen,
  onClose,
  padId,
  rigId,
  onWellClick,
  onWellAdd,
  onWellEditingClick,
  onChange,
}: Props) {
  const rootStore = useStore();

  const { editing } = useStore();

  const [store] = useState(() => new PadInfoSidebarStore(rootStore, rigId));
  const [activeKey, setActiveKey] = useState<string>(PadSidebar.Tabs.mainData);

  useEffect(() => {
    store.init();
  }, [store]);

  useEffect(() => {
    if (hasValue(editing.actualPlanVersionId) && !!store.viewProvider) {
      store.loadPad(padId, editing.actualPlanVersionId);
    }
  }, [editing.actualPlanVersionId, padId, store, store.viewProvider]);

  // Отключенный функционал перемещения и удаления кустов/скважин
  // useEffect(() => {
  //   store.setChangeHandler(onChange || null);
  // }, [onChange, store]);

  const tabs = store.viewProvider?.tabsSwitchButtonsGroup || [];
  const isLoading = store.isLoading || store.padStore.isLoading;
  const activeTab = store.viewProvider?.tabs.find((tab) => tab.fieldId === activeKey);

  const getTitle = (): string | undefined => {
    if (store.padStore.isLoading) {
      return;
    }

    if (store.padStore.pad && store.viewProvider) {
      return store.getSidebarTitle(store.padStore.pad.data) || '';
    }
  };

  return (
    <Sidebar isOpened={isOpen} onClose={onClose} title={getTitle()} size="l">
      <div className={styles.header}>
        <SwitchButtonGroup
          items={tabs}
          defaultOptionKey={activeKey}
          value={activeKey}
          disabled={isLoading}
          className={styles.tabs}
          onChange={setActiveKey}
        />
      </div>

      {isLoading && (
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} />
        </div>
      )}

      <InfoSidebarTabContent activeTab={activeTab} onWellClick={onWellClick} />
    </Sidebar>
  );
});
