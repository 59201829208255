import { TDictObject } from 'src/api/directories/types';
import { CheckboxItem } from 'src/features/planning/views/plan-table/table-title/combo-box-filter/types';
import { FilterType } from 'src/pages/plan-page/types';

export function mapObjectItems(
  object: TDictObject[] | null,
  objectField?: string,
  currentFilter?: FilterType
): CheckboxItem[] {
  if (object && objectField) {
    return object.map((item) => {
      return {
        value: item.id,
        label: objectField in item.data ? item.data[objectField].toString() : null,
        isChecked: currentFilter?.valueArray?.includes(item.id) || false,
      };
    });
  }

  return [];
}

export function serializeFilterValues(fieldId: string, filterValues: CheckboxItem[]): FilterType {
  return {
    fieldId,
    valueArray: filterValues.reduce<Array<string | number>>((prev, curr) => {
      if (curr.isChecked) {
        prev.push(curr.value);
      }

      return prev;
    }, []),
  };
}
