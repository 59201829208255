import { TFunction } from 'i18next';
import moment from 'moment';

import { DATETIME_FORMAT } from 'src/shared/constants/date';
import { PlanVersion, PlanVersionType, TActualPlansGroups } from 'src/store/comparison/types';
import { DRAFT_ASSIGNMENT } from 'src/store/drafts/types';

export function mapActualData(data: PlanVersion[]): TActualPlansGroups[] {
  const publicPlans: TActualPlansGroups = { type: 'actual', name: 'actualPublic', plans: [] };
  const personalPlans: TActualPlansGroups = { type: 'actual', name: 'actualPersonal', plans: [] };
  const drafts: TActualPlansGroups = { type: 'actual', name: 'drafts', plans: [] };

  data.forEach((plan) => {
    if (PlanVersionType.personal === plan.data.versionType) {
      personalPlans.plans.push(plan);
    }

    if (PlanVersionType.draft === plan.data.versionType) {
      drafts.plans.push(plan);
    }

    if ([PlanVersionType.public, PlanVersionType.current].includes(plan.data.versionType)) {
      publicPlans.plans.push(plan);
    }
  });

  return [publicPlans, personalPlans, drafts];
}

export function getDraftTitle(draftVersion: PlanVersion, t: TFunction): string {
  const createdAt = moment(Number(draftVersion.createdAt)).format(DATETIME_FORMAT);
  const draftName = draftVersion.data.name;

  switch (true) {
    case draftName.includes(DRAFT_ASSIGNMENT.editing):
      return t(`drawers:planVersion.${DRAFT_ASSIGNMENT.editing}`, { createdAt });

    case draftName.includes(DRAFT_ASSIGNMENT.import):
      return t(`drawers:planVersion.${DRAFT_ASSIGNMENT.import}`, { createdAt });

    case draftName.includes(DRAFT_ASSIGNMENT.addWell):
      return t(`drawers:planVersion.${DRAFT_ASSIGNMENT.addWell}`, { createdAt });

    default:
      return `${draftVersion.data.name} ${createdAt}`;
  }
}
