import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { Dnd } from '../../../features/editing/types';
import { RigsChartDataModel } from '../../../features/rigs-chart/data/rigs-chart-data-model';
import { Range } from '../../../layers/model';
import { NeighborScheme } from '../../../shared/entities/neighbor-scheme';
import { RawWell } from '../../../types';

import { PadRigOperation } from './pad-rig-operation';

/** Well rig operation on rigs chart. */
export class WellRigOperation
  implements Dnd.Draggable<WellRigOperation, PadRigOperation>, RigsChartDataModel.IChartWellRigOperation
{
  readonly uniqueCreationKey: string;
  readonly id: number;
  readonly data: RawWell;
  readonly parentRig: RigsChartDataModel.IChartRig;

  start: number | null;
  end: number | null;
  parentPad?: PadRigOperation;
  neighborScheme?: NeighborScheme<WellRigOperation>;

  @observable y: Range<number> = { start: 0, end: 0 };

  constructor(
    item: RawWell,
    id: number,
    startDate: number | null,
    endDate: number | null,
    parentRig: RigsChartDataModel.IChartRig,
    parentPad?: PadRigOperation,
    y?: Range<number>
  ) {
    this.data = item;
    this.id = id;
    this.uniqueCreationKey = uuidv4();
    this.parentRig = parentRig;
    this.parentPad = parentPad;
    this.start = startDate;
    this.end = endDate;

    if (y) {
      this.setY(y);
    }

    makeObservable(this);
  }

  /** Start and end of drilling stage only. */
  get x(): Range<number> | null {
    const { start, end } = this;

    if (!start || !end) {
      return null;
    }

    return {
      start,
      end,
    };
  }

  get title(): string {
    return `Well ${this.id}`;
  }

  getKey(prefix?: string): string {
    if (prefix) {
      return `${prefix}-well-${this.uniqueCreationKey}`;
    }

    return `well-${this.uniqueCreationKey}`;
  }

  getFieldValue(fieldName: string): unknown {
    if (fieldName in this.data) {
      return this.data[fieldName];
    }
  }

  @action.bound
  setY(value: Range<number>): void {
    this.y = value;
  }

  setNeighborScheme(scheme: NeighborScheme<WellRigOperation>): void {
    this.neighborScheme = scheme;
  }

  clone(): WellRigOperation {
    return new WellRigOperation(this.data, this.id, this.start, this.end, this.parentRig, this.parentPad, this.y);
  }
}
