import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Checkbox } from 'src/shared/components/checkbox';
import { Loader } from 'src/shared/components/loader';
import { PlanVersion } from 'src/store/comparison/types';

import styles from './analytics-version-group.module.scss';

type Props = {
  version: PlanVersion;
  isChosen: boolean;
  isDisabled: boolean;
  toggleVersion: () => Promise<void>;
};

export const AnalyticsVersionItem = observer(function AnalyticsVersionItem({
  version,
  isChosen,
  isDisabled,
  toggleVersion,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async () => {
    setIsLoading(true);
    await toggleVersion();
    setIsLoading(false);
  };

  return (
    <li className={styles.listItem}>
      {isLoading ? (
        <Loader size="small" className={styles.loader} />
      ) : (
        <Checkbox className={styles.checkbox} isDisabled={isDisabled} isChecked={isChosen} onChange={handleChange} />
      )}

      <p className={styles.listItemText}>{version.data.name}</p>
    </li>
  );
});
