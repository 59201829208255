import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Collapse, { Panel } from 'src/shared/components/collapse/collapse';
import { useStore } from 'src/store';
import { TAnalyticsPlansGroup } from 'src/store/comparison/types';

import { AnalyticsVersionItem } from './analytics-version-item';

import styles from './analytics-version-group.module.scss';

type Props = {
  versionsGroup: TAnalyticsPlansGroup;
};

export const AnalyticsVersionGroup = observer(function AnalyticsVersionGroup({ versionsGroup }: Props) {
  const { t } = useTranslation();
  const { planVersion } = useStore();

  if (!versionsGroup.months.length) {
    return null;
  }

  return (
    <Collapse key={versionsGroup.year} defaultActiveKey={versionsGroup.year}>
      <Panel key={versionsGroup.year} header={versionsGroup.year} className={styles.text}>
        {versionsGroup.months.map((monthGroup) => (
          <Collapse key={monthGroup.month} defaultActiveKey={monthGroup.month}>
            <Panel key={monthGroup.month} header={t(monthGroup.month)} className={styles.text}>
              <ul>
                {monthGroup.plans.map((plan) => {
                  const isChosen = planVersion.chosenAnalyticsVersions.has(plan.id);
                  const isDisabled = planVersion.chosenAnalyticsVersions.size === 10 && !isChosen;
                  const toggleversion = async () => await planVersion.toggleVersionForAnalytics(plan);

                  return (
                    <AnalyticsVersionItem
                      key={plan.id}
                      version={plan}
                      isChosen={isChosen}
                      isDisabled={isDisabled}
                      toggleVersion={toggleversion}
                    />
                  );
                })}
              </ul>
            </Panel>
          </Collapse>
        ))}
      </Panel>
    </Collapse>
  );
});
