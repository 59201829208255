import { agent } from 'src/api/agent';
import { mapMatchExcelHeaders, mapTripleData, serializeMatchHeadersData } from 'src/api/import-file/serializers';
import { throwApiError } from 'src/api/utils';
import {
  MatchedItemType,
  MatchHeadersType,
  TTriple,
  TTriplesList,
} from 'src/pages/import-matching-page/import-matching-page.store';
import { Tab } from 'src/shared/entities/abstract-control-entities';

import { serializeFormForRequest } from '../new-well/serializers/serialize-form-for-request';
import { TIdTriple } from '../new-well/types';

import { importAgent } from './agent';
import { UploadResponseType, MatchResponseType, TripleType, TJob, TParseExcelFileResponse } from './types';

export async function fetchTriple(tripleId: number): Promise<TripleType> {
  try {
    const { data } = await agent.get<TTriple>(`/object/GOplan_PlanWellTriple/${tripleId}`);

    return mapTripleData(data);
  } catch (e) {
    throwApiError(e);
  }
}

export async function fetchTriplesList(planVersionId: number): Promise<TTriplesList> {
  try {
    const { data } = await importAgent.get<TTriplesList>(`/import/triples/?versionId=${planVersionId}`);

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function deletePlanVersion(planVersionId: number): Promise<void> {
  try {
    await agent.delete<TTriplesList>(`/version/${planVersionId}`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function deleteTriple(planVersionId: number, geologicalTaskId: number): Promise<void> {
  try {
    await agent.delete<TTriplesList>(`/well/${geologicalTaskId}`, {
      params: {
        planVersionId,
        geologicalTaskId,
      },
    });
  } catch (e) {
    throwApiError(e);
  }
}

export async function cleanTripleValidation(tripleId: number): Promise<void> {
  try {
    await agent.patch<TTriplesList>(`/object/GOplan_PlanWellTriple/${tripleId}`, {
      $validation: null,
    });
  } catch (e) {
    throwApiError(e);
  }
}

export async function changeTriple(tabs: Tab[], planVersionId: number, idsList: TIdTriple[]): Promise<void> {
  const serializedForm = serializeFormForRequest(tabs, idsList);

  try {
    await agent.patch('/well', serializedForm, { params: { planVersionId } });
  } catch (e) {
    throwApiError(e);
  }
}

export async function uploadFile(file: File): Promise<UploadResponseType> {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await importAgent.post<UploadResponseType>('/import/excel', formData, {
      headers: { 'content-type': 'multi-part/formdata' },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function matchExcelHeaders(fileId: number, sheet: string): Promise<MatchHeadersType> {
  try {
    const { data } = await importAgent.get<MatchResponseType>(`/import/excel/${fileId}/match`, { params: { sheet } });

    return mapMatchExcelHeaders(data);
  } catch (e) {
    throwApiError(e);
  }
}

export async function parseExcelData(
  fileId: number,
  sheetName: string,
  matchedData: MatchedItemType[]
): Promise<TParseExcelFileResponse> {
  try {
    const { data } = await importAgent.post<TParseExcelFileResponse>(
      `/import/excel/${fileId}/parse`,
      serializeMatchHeadersData(matchedData),
      {
        params: { sheetName },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function cancelJob(jobId: number): Promise<void> {
  try {
    await agent.delete(`/job/${jobId}`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function getJobInfo(jobId: number): Promise<TJob> {
  try {
    const { data } = await agent.get<TJob>(`/job/${jobId}`);

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function getJobList(): Promise<TJob[]> {
  try {
    const { data } = await agent.get<TJob[]>('/job/');

    return data;
  } catch (e) {
    throwApiError(e);
  }
}
