import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { getSub } from 'src/shared/utils/auth';
import { PlanVersion } from 'src/store/comparison/types';

export class PlanVersionsApi {
  async getVersions(): Promise<PlanVersion[]> {
    try {
      const { data } = await agent.get<PlanVersion[]>('version/list', {
        params: { ownerUserId: getSub() },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }
}
