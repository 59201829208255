import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Dnd } from 'src/features/drilling-chart/features/editing/types';
import { ReactComponent as MoveIcon } from 'src/shared/assets/icons/move.svg';
import { hasValue } from 'src/shared/utils/common';

import { SidebarWellRigOperation } from '../../entities';

import styles from './well-title.module.scss';

interface Props {
  well: SidebarWellRigOperation;
  listeners: Dnd.DraggableListeners;
}

export const WellTitle = observer(function WellTitle({ well, listeners }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container} {...listeners}>
      <div className={styles.iconAndTitleWrapper}>
        <div className={styles.moveIconWrapper}>
          <MoveIcon className={styles.moveIcon} />
        </div>
        <p className={styles.title}>{well.title}</p>
      </div>
      {hasValue(well.rigOperationIndex) && (
        <p className={styles.rigOperation}>
          {t('drawers:unplannedRigOperations.rigOperationIndex', { index: well.rigOperationIndex })}
        </p>
      )}
    </div>
  );
});
