import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { MouseEventHandler, PointerEventHandler, ReactNode } from 'react';

import { ReactComponent as ExpandIcon } from 'src/shared/assets/icons/expand-down.svg';

import styles from './colored-card.module.scss';

interface Props {
  title: React.ReactNode;
  text?: string;
  icons?: ReactNode;
  extra?: ReactNode;
  showExpandButton?: boolean;
  cardColor?: string;
  className?: string;
  isInteractive?: boolean;
  onPointerDown?: PointerEventHandler;
  onPointerUp?: PointerEventHandler;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onShowInfo?(): void;
}

export const ColoredCard = observer(function ColoredCard({
  cardColor,
  title,
  text,
  icons,
  extra,
  showExpandButton,
  className,
  isInteractive,
  onPointerDown,
  onPointerUp,
  onShowInfo,
  onClick,
}: Props) {
  return (
    <div
      className={clsx(className, styles.card, isInteractive && styles.cardInteractive)}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onClick={onClick}
    >
      <div className={styles.cardBorder} style={{ backgroundColor: cardColor }} />

      <div className={styles.cardHeader}>
        {extra}
        <h3 className={styles.cardHeaderTitle}>{title}</h3>
        <div className={styles.cardHeaderIcons}>{icons}</div>
      </div>

      <div className={styles.cardBody}>
        <p className={styles.cardBodyText}>{text}</p>
        {showExpandButton && (
          <button className={styles.cardBodyButton} onClick={onShowInfo}>
            <ExpandIcon />
          </button>
        )}
      </div>
    </div>
  );
});
