import { action, autorun, makeObservable } from 'mobx';

import { RootStore } from 'src/store';

import { Item } from '../../../../shared/entities/abstract-control-entities';
import { PassingIntervalField } from '../../../../shared/entities/control-entities';
import { FormStore } from '../../entities/form.entity';
import { FormPlugin } from '../abstract-form-plugin.entity';

export class PassingValueFormulaPlugin extends FormPlugin {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item.fieldId === 'passingValue') {
        const approachesList = form.approachesTab?.approachesList;
        if (!!approachesList) {
          const controllingControl = form.fields['sectionsDetailing'];

          const disposer = autorun(() => {
            if (!controllingControl || !controllingControl.value) {
              if (item.value === 0) {
                item.setValue(null);
              }

              return;
            }
            const totalPassingValue = approachesList.approaches.reduce((prevAppr, curAppr) => {
              return (
                prevAppr +
                curAppr.stagesList.stages.reduce((prevStage, curStage) => {
                  return (
                    prevStage +
                    curStage.sectionsList.sections.reduce((prevSec, curSec) => {
                      const passingField = curSec.fieldsList.find(
                        (field): field is PassingIntervalField => field instanceof PassingIntervalField
                      );
                      if (!passingField) return prevSec;
                      const curSecPassingValue = passingField.passingValue;
                      return prevSec + (curSecPassingValue ?? 0);
                    }, 0)
                  );
                }, 0)
              );
            }, 0);
            item.tryToSetRawValue(totalPassingValue);
          });
          disposers.push(disposer);
        }
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
