import { EditingSettingsItem } from 'src/features/drilling-chart/shared/editing-settings-item';
import { TRefQuery } from 'src/store/directories/types';

export namespace ViewSettings {
  export enum ViewSettingsTabsDataTypes {
    flat = 'flat',
    grouped = 'grouped',
  }

  export type FlatSettingsControls = {
    fieldId: string;
    tabId: string;
    type: ViewSettingsTabsDataTypes.flat;
    items: EditingSettingsItem[];
  };

  export type GroupedSettingsControls = {
    fieldId: string;
    tabId: string;
    type: ViewSettingsTabsDataTypes.grouped;
    items: GroupedSettingsControlsGroupData[];
  };

  export type GroupedSettingsControlsGroupData = { title: string; fieldId: string; items: EditingSettingsItem[] };

  export type SettingsControlWithOrder = {
    view: SettingsControlView;
    values: SettingsControlValue;
    order: number;
  };

  export type SettingsControl = { view: ViewSettings.SettingsControlView; values: ViewSettings.SettingsControlValue };

  export type SettingsControlView = {
    control: string;
    fieldId: string;
    attr?: string;
    attrName?: string;
    objectType?: string;
    refObjectAttr?: string;
    refObjectType?: string;
    refQuery?: TRefQuery;
    numberOfDecimalPlaces?: number;
    attrConcat?: string[];
    type?: string;
    delimiter?: string;
  };

  export type Rig = {
    rigId: number;
    groupId: number;
  };

  export type RigsGroup = {
    groupId: number;
    isCollapsed: boolean;
  };

  export type RigsOrderSettingsValues = {
    rigs: Rig[];
    groups: RigsGroup[];
  };

  export type RigsOrderSettingsValuesByGrouping = {
    grouping: string;
    settings: RigsOrderSettingsValues;
  };

  /** Settings that not depend on view. */
  export type RigsOwnSettingsValues = Partial<{
    /** Display order of rigs and rig groups by grouping.  */
    rigsOrder: RigsOrderSettingsValuesByGrouping[];
  }>;

  export type WellsGroup = {
    groupId: number;
    isCollapsed: boolean;
  };

  export type WellsOrderSettingsValues = {
    groups: WellsGroup[];
  };

  export type WellsOrderSettingsValuesByGrouping = {
    grouping: string;
    settings: WellsOrderSettingsValues;
  };

  /** Settings that not depend on view. */
  export type WellsOwnSettingsValues = Partial<{
    /** Display order of wells and well groups by grouping.  */
    wellsOrder: WellsOrderSettingsValuesByGrouping[];
  }>;

  export type InfoSettingsValues = Record<string, RawSettingValue>;

  /** Settings that depend on view from back-end. */
  export type ViewSettingsValues = Partial<{
    /** Rig/well/pad attributes display settings. */
    info: InfoSettingsValues;
  }>;

  export type RawSettingsValues = ViewSettingsValues;

  export type RigsChartRawSettingsValues = ViewSettingsValues & RigsOwnSettingsValues;

  export type WellsChartRawSettingsValues = ViewSettingsValues & WellsOwnSettingsValues;

  export type RawSettingValue =
    | {
        type: ViewSettingsTabsDataTypes.flat;
        settings: SettingsControlValue[];
      }
    | {
        type: ViewSettingsTabsDataTypes.grouped;
        settings: SettingsGroupValues[];
      };

  export type SettingsGroupValues = {
    fieldId: string;
    items: SettingsControlValue[];
  };

  export type SettingsControlValue = {
    fieldId: string;
    isShown: boolean;
  };

  /** Типизировання вьюха настроек отображения */
  export type TypedSettingsControls = Record<string, TypedFlatSettingsControls | TypedGroupedSettingsControls>;

  export type TypedFlatSettingsControls = {
    type: ViewSettings.ViewSettingsTabsDataTypes.flat;
    fieldId: string;
    order?: number;
    items: SettingsControlView[];
  };

  export type TypedGroupedSettingsControls = {
    type: ViewSettings.ViewSettingsTabsDataTypes.grouped;
    fieldId: string;
    order?: number;
    groups: RawSettingsGroup[];
  };

  /** Нетипизировання вьюха настроек отображения, приходящая непосредственно от бэкенда */
  export type RawSettingsControls = Record<string, RawGroupedSettingsControls | RawFlatSettingsControls>;

  export type RawFlatSettingsControls = {
    fieldId: string;
    order?: number;
    items: SettingsControlView[];
  };

  export type RawGroupedSettingsControls = {
    fieldId: string;
    order?: number;
    groups: RawSettingsGroup[];
  };

  export type RawSettingsGroup = {
    fieldId: string;
    items: SettingsControlView[];
  };
}

export namespace IndicatorsView {
  export type TableSettingsItemWithLabels = TableSettingsItem & {
    settingsLabel: string;
  };

  export type TableSettingsItem = {
    control: string;
    fieldId: string;
    settingsFieldId: string;
    type: string;
  };

  export type TableSettings = {
    items: TableSettingsItem[];
  };

  export type Settings = {
    table: TableSettings;
  };

  export type SettingsValue = {
    fieldId: string;
    isShown: boolean;
  };

  export type TableSettingsValues = Partial<{
    items: SettingsValue[];
  }>;

  export type SettingsValues = Partial<{
    table: TableSettingsValues;
  }>;
}
