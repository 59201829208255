import { observable, makeObservable, action, computed } from 'mobx';
import { Moment } from 'moment';

import { TDictObject } from 'src/api/directories/types';
import { mapApproachStage } from 'src/api/new-well/serializers/approaches-serializers';
import { mapComboBoxItem } from 'src/api/new-well/serializers/combobox-serializers/map-combobox';
import { serializeStage } from 'src/api/new-well/serializers/serialize-form-for-request';
import { TApproachStageSectionRaw, TApproachStageRaw, TSerializedSection } from 'src/api/new-well/types';
import { Item, ItemContainer, ValidatableItem } from 'src/shared/entities/abstract-control-entities';
import { DateIntervalField, RegularComboBox } from 'src/shared/entities/control-entities';
import { getDurationByStartAndEndDates } from 'src/shared/utils/get-duration-by-start-and-end-dates';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { Directories } from 'src/store/directories/directories.store';

import { TStartDateData } from '../types';

import { ApproachStageSectionsList } from './approach-stage-sections-list.entity';

type TApproachStageData = {
  fields: Item<unknown>[];
  sectionReference?: TApproachStageSectionRaw;
  stageReference: TApproachStageRaw;
  savedSections?: TSerializedSection[];
  directories: Directories;
  wellLifeCycleStageId: TDictObject;
  parentControl?: ItemContainer;
};

export class ApproachStage extends ItemContainer {
  readonly id: number;
  readonly stageReference: TApproachStageRaw;
  // TODO: сделать отдельной опцией типа секций, чтобы референс можно было сделать приватным
  readonly sectionReference?: TApproachStageSectionRaw;
  readonly directories: Directories;
  readonly stageType: TDictObject;
  readonly sectionsList: ApproachStageSectionsList;
  readonly stageTypeControl: RegularComboBox;
  onDeleteCallbacks: VoidFunction[] = [];

  @observable stageDisabledStartDate: Moment | null = null;
  @observable isStartDateDisabled: boolean = false;
  @observable isVisuallyDisabled = false;

  constructor(data: TApproachStageData) {
    super(data.fields, data.parentControl);
    this.sectionsList = new ApproachStageSectionsList({
      directories: data.directories,
      sectionTemplate: data.stageReference.sectionTemplate,
      savedSections: data.savedSections,
      stage: this,
    });
    this.directories = data.directories;
    this.sectionReference = data.sectionReference;
    this.stageReference = data.stageReference;
    this.stageType = data.wellLifeCycleStageId;
    this.id = getRandomNumber();
    this.stageTypeControl = this.getStageTypeControl();

    makeObservable(this);
  }

  private getStageTypeControl(): RegularComboBox {
    const control = mapComboBoxItem({
      item: this.stageReference.wellLifeCycleStageId,
      directories: this.directories,
      savedValue: this.stageType.id,
    });

    this.fields[control.fieldId] = control;

    return control;
  }

  @computed
  get calculatedStartDate(): Moment | null {
    return this.sectionsList.sections[0]?.dateField?.startDate || null;
  }

  @computed
  get calculatedEndDate(): Moment | null {
    const lastSectionsIndex = this.sectionsList.sections.length - 1;

    if (lastSectionsIndex < 0) {
      return null;
    }

    return this.sectionsList.sections[lastSectionsIndex].dateField?.endDate || null;
  }

  @computed
  get calculatedDuration(): number | null {
    if (!this.calculatedStartDate || !this.calculatedEndDate) return null;
    return getDurationByStartAndEndDates(this.calculatedStartDate, this.calculatedEndDate);
  }

  @computed
  get dateField(): DateIntervalField | null {
    return this.fieldsList.find((field): field is DateIntervalField => field instanceof DateIntervalField) ?? null;
  }

  @action.bound
  setStartDateByDateData(dateData: TStartDateData) {
    this.dateField?.setIsStartDateDisabled(dateData.isDisabled);
    this.isStartDateDisabled = dateData.isDisabled;
    if (this.dateField?.startDate?.valueOf() !== dateData.startDate?.valueOf()) {
      this.dateField?.setStartDate(dateData.startDate);
    }
  }

  @action.bound
  setIsVisuallyDisabled(is: boolean) {
    this.isVisuallyDisabled = is;
    this.sectionsList.setIsVisuallyDisabled(is);
  }

  @action.bound
  addNewSection(sectionTypeId: string | number): void {
    this.dateField?.clearError();
    this.sectionsList.addNewSection(sectionTypeId);
  }

  @action.bound
  validate(): void {
    this.fieldsList.forEach((field) => {
      if (field instanceof ValidatableItem) {
        field.hasErrors();
      }
    });
    this.sectionsList.validate();
  }

  clone(): ApproachStage {
    const serializedCurrentStage = serializeStage(this);
    return mapApproachStage(
      {
        reference: this.stageReference,
        wellLifeCycleStageId: this.stageType,
        directories: this.directories,
        parentControl: this.parentControl,
      },
      serializedCurrentStage
    );
  }
}
