import { autorun, reaction } from 'mobx';

import { Approach, ApproachStage, ApproachStageSection } from '../entities/approach-entities';
import { ApproachesList } from '../entities/approach-entities/approaches-list.entity';
import { FormStore } from '../entities/form.entity';

import { FormPlugin } from './abstract-form-plugin.entity';

export class ApproachesDatesDependencyPlugin extends FormPlugin {
  private initializedApproaches = new Set<Approach>();
  private initializedStages = new Set<ApproachStage>();
  private initializedSections = new Set<ApproachStageSection>();

  connect(form: FormStore) {
    const disposers: VoidFunction[] = [];

    return autorun(() => {
      if (!form.approachesTab) {
        return;
      }
      const approachesList = form.approachesTab.approachesList;
      approachesList.approaches.forEach((approach) => {
        if (!this.initializedApproaches.has(approach)) {
          disposers.push(this.initializeApproach(approach, approachesList));
        }
      });

      return () => {
        disposers.forEach((disposer) => disposer());
      };
    });
  }

  initializeApproach(approach: Approach, approachesList: ApproachesList): VoidFunction {
    this.initializedApproaches.add(approach);

    const approachesDatesDependencyDisposers = this.connectCurrentApproachDateWithPrevApproachDate(
      approach,
      approachesList.approaches
    );
    const approachesDatesCalculatesDisposers = this.calculateApproachDates(approach, approachesList.approaches);
    const stagesinitializerDisposer = this.initializeStages(approachesList, approach);

    const initApproachDisposer = () => {
      approachesDatesDependencyDisposers();
      approachesDatesCalculatesDisposers();
      stagesinitializerDisposer();
    };

    return initApproachDisposer;
  }

  calculateApproachDates(approach: Approach, approaches: Approach[]): VoidFunction {
    return autorun(() => {
      const index = approaches.indexOf(approach);
      if (index === 0) {
        approach.setStartDate(approach.stagesList.startDate);
      }
      approach.setEndDate(approach.stagesList.endDate);
    });
  }

  connectCurrentApproachDateWithPrevApproachDate(approach: Approach, approaches: Approach[]): VoidFunction {
    return autorun(() => {
      const index = approaches.indexOf(approach);
      const prevApproach = approaches[index - 1];
      if (!prevApproach || !prevApproach.endDate) {
        approach.setStartDate(null);
        return;
      }
      approach.setStartDate(prevApproach.endDate);
    });
  }

  initializeStages(approachesList: ApproachesList, approach: Approach): VoidFunction {
    const stagesDisposers: VoidFunction[] = [];
    const stagesinitDisposer = autorun(() => {
      approach.stagesList.stages.forEach((stage) => {
        if (!this.initializedStages.has(stage)) {
          stagesDisposers.push(this.initializeStage(approachesList, approach, stage));
        }
      });
    });

    return () => {
      stagesinitDisposer();
      stagesDisposers.forEach((disposer) => disposer());
    };
  }

  initializeStage(approachesList: ApproachesList, approach: Approach, stage: ApproachStage): VoidFunction {
    this.initializedStages.add(stage);
    const sectionsDisposers: VoidFunction[] = [];

    const stageDependencyOnApproachDisposer = this.setStageDatesDependsOnApproachDates(approachesList, approach, stage);
    const stagesDatesDependencyDisposer = this.setStagesDatesDependencies(approach, stage);
    const stagesDatesDependencyOnSectionsDates = this.setStagesDatesDependsOnSectionsDates(approach, stage);
    const initializeSectionsDisposer = autorun(() => {
      stage.sectionsList.sections.forEach((section) => {
        if (!this.initializedSections.has(section)) {
          sectionsDisposers.push(this.initializeSection(approach, stage, section));
        }
      });
    });

    const initStageDisposer = () => {
      stageDependencyOnApproachDisposer();
      stagesDatesDependencyDisposer();
      stagesDatesDependencyOnSectionsDates();
      initializeSectionsDisposer();
      sectionsDisposers.forEach((disposer) => disposer());
    };

    stage.onDeleteCallbacks.push(initStageDisposer);

    return initStageDisposer;
  }

  setStageDatesDependsOnApproachDates(
    approachesList: ApproachesList,
    approach: Approach,
    stage: ApproachStage
  ): VoidFunction {
    return autorun(() => {
      const index = approach.stagesList.stages.indexOf(stage);
      const approachIndex = approachesList.approaches.indexOf(approach);
      if (approachIndex === 0 && index === 0) {
        stage.dateField?.setDisabledStartDate(null);
      }
      if (approachIndex !== 0 && index === 0 && approach.startDate) {
        if (stage.dateField?.disabledStartDate?.valueOf() !== approach.startDate.valueOf()) {
          stage.dateField?.setDisabledStartDate(approach.startDate);
        }
        if (stage.dateField?.startDate && approach.startDate.valueOf() > stage.dateField.startDate.valueOf()) {
          stage.dateField.setStartDate(approach.startDate);
        }
      }
    });
  }

  setStagesDatesDependencies(approach: Approach, stage: ApproachStage): VoidFunction {
    return autorun(() => {
      const index = approach.stagesList.stages.indexOf(stage);
      const prevStage = approach.stagesList.stages[index - 1];

      stage.dateField?.setIsStartDateDisabled(index > 0);

      if (prevStage) {
        if (prevStage.dateField) {
          if (stage.dateField?.startDate?.valueOf() !== prevStage.dateField.endDate?.valueOf()) {
            stage.dateField?.setStartDate(prevStage.dateField.endDate);
          }

          stage.dateField?.setDisabledStartDate(prevStage.dateField.endDate || prevStage.dateField.disabledStartDate);
        }
      }
    });
  }

  setStagesDatesDependsOnSectionsDates(approach: Approach, stage: ApproachStage): VoidFunction {
    const setStartAndEndStageDatesBySectionListDatesDisposer = autorun(() => {
      if (stage.sectionsList.sections.length) {
        const stageIndex = approach.stagesList.stages.indexOf(stage);
        const startDate = stage.sectionsList.startDate;
        if (stageIndex === 0) {
          if (stage.dateField?.startDate?.valueOf() !== startDate?.valueOf() && !stage.dateField?.startDate) {
            stage.dateField?.setStartDate(startDate || null);
          }
        }

        const endDate = stage.sectionsList.endDate;
        if (endDate?.valueOf() !== stage.dateField?.endDate?.valueOf()) {
          stage.dateField?.setEndDate(endDate || null);
        }
      }
    });

    const setStageStartDateByFirstSectionStartDateDisposer = reaction(
      () => stage.sectionsList.startDate,
      (startDate) => {
        if (stage.dateField?.startDate?.valueOf() !== startDate?.valueOf()) {
          stage.dateField?.setStartDate(startDate);
        }
      }
    );

    return () => {
      setStartAndEndStageDatesBySectionListDatesDisposer();
      setStageStartDateByFirstSectionStartDateDisposer();
    };
  }

  initializeSection(approach: Approach, stage: ApproachStage, section: ApproachStageSection): VoidFunction {
    this.initializedSections.add(section);
    const sectionsDatesDependencies = this.setSectionsDatesDependency(section, stage);
    const sectionsDatesDependenciesOnStageDatesDisposer = this.setSectionsDatesDependsOnStageDates(section, stage);
    const isSectionStartDateDisabledDisposer = this.setIsSectionStartDateDisabled(approach, stage, section);

    const initSectionDisposer = () => {
      sectionsDatesDependencies();
      sectionsDatesDependenciesOnStageDatesDisposer();
      isSectionStartDateDisabledDisposer();
    };

    section.onDeleteCallbacks.push(initSectionDisposer);

    return initSectionDisposer;
  }

  setSectionsDatesDependency(section: ApproachStageSection, stage: ApproachStage): VoidFunction {
    return autorun(() => {
      const index = stage.sectionsList.sections.indexOf(section);
      const prevSection = stage.sectionsList.sections[index - 1];

      section.dateField?.setIsStartDateDisabled(section.dateField.isStartDateDisabled || index > 0);

      if (prevSection) {
        if (prevSection.dateField) {
          if (section.dateField?.startDate?.valueOf() !== prevSection.dateField.endDate?.valueOf()) {
            section.dateField?.setStartDate(prevSection.dateField.endDate);
          }

          if (
            section.dateField?.disabledStartDate?.valueOf() !== prevSection.dateField.endDate?.valueOf() ||
            (!prevSection.dateField.endDate &&
              section.dateField?.disabledStartDate?.valueOf() !== prevSection.dateField.disabledStartDate?.valueOf())
          ) {
            section.dateField?.setDisabledStartDate(
              prevSection.dateField.endDate || prevSection.dateField.disabledStartDate
            );
          }
        }
      }
    });
  }

  setSectionsDatesDependsOnStageDates(section: ApproachStageSection, stage: ApproachStage): VoidFunction {
    return autorun(() => {
      const index = stage.sectionsList.sections.indexOf(section);
      if (index === 0) {
        if (section.dateField?.startDate?.valueOf() !== stage.dateField?.startDate?.valueOf()) {
          section.dateField?.setStartDate(stage.dateField?.startDate || null);
        }
        if (section.dateField?.disabledStartDate?.valueOf() !== stage.dateField?.disabledStartDate?.valueOf()) {
          section.dateField?.setDisabledStartDate(stage.dateField?.disabledStartDate || null);
        }
      }
    });
  }

  setIsSectionStartDateDisabled(approach: Approach, stage: ApproachStage, section: ApproachStageSection) {
    return autorun(() => {
      const stageIndex = approach.stagesList.stages.indexOf(stage);
      const sectionIndex = stage.sectionsList.sections.indexOf(section);
      section.dateField?.setIsStartDateDisabled(stageIndex !== 0 || sectionIndex !== 0);
    });
  }
}
