import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArchiveImg } from 'src/shared/assets/img/archiv.svg';
import { Button } from 'src/shared/components/button';
import { Modal } from 'src/shared/components/modal/modal';

import styles from './move-to-unplanned-modal.module.scss';

type Props = {
  isOpened: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const MoveToUnplannedModal = observer(function MoveToUnplannedModal({ onCancel, onConfirm, isOpened }: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpened={isOpened} className={styles.modal} closeOnClickOutside={false}>
      <ArchiveImg className={styles.image} />
      <h2 className={styles.title}>{t('modals:MoveToUnplannedModal.title')}</h2>
      <p className={styles.description}>{t('modals:MoveToUnplannedModal.desc')}</p>
      <div className={styles.buttonsWrapper}>
        <Button variant="secondary" onClick={onCancel} className={styles.button}>
          {t('common:Buttons.cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm} className={styles.button}>
          {t('common:Buttons.move')}
        </Button>
      </div>
    </Modal>
  );
});
