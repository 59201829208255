import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { CopyWellModal } from 'src/features/modals/copy-well-modal';
import { ReactComponent as CopyIcon } from 'src/shared/assets/icons/copy.svg';
import { assert } from 'src/shared/utils/assert';
import { TableRow } from 'src/store/table/types';
import { getCellValue } from 'src/store/table/utils';

import styles from './copy-table-cell.module.scss';

interface Props {
  row: TableRow;
  onCopyGeologicalTask(geologicalTaskId: number, wellPlacementId: number): void;
}

export const CopyTableCell = observer(function CopyTableCell({ row, onCopyGeologicalTask }: Props) {
  const [isCopyWellModalOpened, setIsCopyWellModalOpened] = useState<boolean>(false);

  function onCopyWell(): void {
    // Hardcode-attr
    const geologicalTaskId = getCellValue(row['GOplan_GeologicalTask.id']);
    const wellPlacementId = getCellValue(row['GOplan_WellPlacement.id']);

    assert(typeof geologicalTaskId === 'number' && typeof wellPlacementId === 'number');

    onCopyGeologicalTask(geologicalTaskId, wellPlacementId);

    onCloseCopyWellModal();
  }

  function onOpenCopyWellModal(): void {
    setIsCopyWellModalOpened(true);
  }

  function onCloseCopyWellModal(): void {
    setIsCopyWellModalOpened(false);
  }

  return (
    <>
      <div className={styles.cell}>
        <button className={styles.cellButton} onClick={onOpenCopyWellModal}>
          <CopyIcon />
        </button>
      </div>
      <CopyWellModal isOpened={isCopyWellModalOpened} onCancel={onCloseCopyWellModal} onConfirm={onCopyWell} />
    </>
  );
});
