import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LoseChangesModal } from 'src/features/modals/lose-changes-modal/lose-changes-modal';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'src/shared/assets/icons/cross.svg';
import { Button } from 'src/shared/components/button/button';
import { ROUTES } from 'src/shared/constants/routes';
import { MenuSubheaderLayout } from 'src/shared/layouts/menu-subheader-layout/menu-subheader-layout';

import styles from './import-matching-page-subheader.module.scss';

type Props = {
  handleSave: () => void;
};

export const ImportMatchingPageSubheader = observer(function ImportMatchingPageSubheader({ handleSave }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoseChangesModalOpened, setIsLoseChangesModalOpened] = useState(false);

  const handleOpen = () => setIsLoseChangesModalOpened(true);
  const handleClose = () => setIsLoseChangesModalOpened(false);
  const handleConfirm = () => {
    navigate(ROUTES.wells);
    handleClose();
  };

  return (
    <MenuSubheaderLayout>
      <div className={styles.wrapper}>
        <p className={styles.text}>{t('matching:header.title')}</p>
        <div className={styles.buttonsWrapper}>
          <Button prefixIcon={<CrossIcon />} variant="danger" onClick={handleOpen}>
            {t('common:Buttons.cancel')}
          </Button>
          <Button prefixIcon={<CheckIcon />} variant="success" onClick={handleSave}>
            {t('common:Buttons.save')}
          </Button>
        </div>
      </div>
      <LoseChangesModal isOpened={isLoseChangesModalOpened} confirmCallback={handleConfirm} handleClose={handleClose} />
    </MenuSubheaderLayout>
  );
});
