import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import {
  WellType,
  IndexedWellType,
  IndexedWellObjectType,
  WellsChangesResponseType,
  OBJECT_TYPE,
  RemovableRigObjectType,
  WellsListResponseType,
  CompareWellsListResponseType,
} from 'src/api/wells-list/types';
import { WELLS_TYPE, SortingObjectType, FilterObjectType } from 'src/pages/wells-page/types';

export async function getWellsList(
  tab: WELLS_TYPE,
  planVersionId: number,
  offset: number,
  limit: number,
  withRigOperation: boolean,
  sorting?: SortingObjectType | null,
  filter?: FilterObjectType[] | null
): Promise<WellsListResponseType> {
  try {
    const { data } = await agent.post<WellsListResponseType>(
      'well/list',
      {
        ...(sorting && { sorting }),
        ...(filter && { filter }),
      },
      {
        params: { tab, planVersionId, offset, limit, withRigOperation },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function getCompareWellsList(
  firstPlanVersionId: number,
  secondPlanVersionId: number,
  tab: WELLS_TYPE,
  offset: number,
  limit: number,
  withRigOperation: boolean,
  sorting: SortingObjectType | null,
  filter: FilterObjectType[] | null
): Promise<CompareWellsListResponseType> {
  try {
    const { data } = await agent.post<CompareWellsListResponseType>(
      'well/list/compare',
      {
        ...(sorting && { sorting }),
        ...(filter && { filter }),
      },
      {
        params: { firstPlanVersionId, secondPlanVersionId, tab, offset, limit, withRigOperation },
      }
    );

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function saveWellsChanges(
  planVersionId: number,
  wellsChanges: WellType
): Promise<WellsChangesResponseType> {
  try {
    const { data } = await agent.patch<WellsChangesResponseType>('well', wellsChanges, { params: { planVersionId } });

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function multiplySaveWellsChanges(
  planVersionId: number,
  wellsChanges: IndexedWellType[]
): Promise<IndexedWellObjectType[]> {
  try {
    const { data } = await agent.patch<IndexedWellObjectType[]>('well/multi', wellsChanges, {
      params: { planVersionId },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function copyGeologicalTask(
  planVersionId: number,
  geologicalTaskId: number,
  wellPlacementId: number
): Promise<Record<string, unknown>> {
  try {
    const { data } = await agent.get<Record<string, unknown>>(`well/copyGeologicalTask/${geologicalTaskId}`, {
      params: { planVersionId, wellPlacementId },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
}

export async function removeRig(
  planVersionId: number,
  objectType: OBJECT_TYPE,
  objectIdList: number[]
): Promise<RemovableRigObjectType[]> {
  try {
    const { data } = await agent.post<RemovableRigObjectType[]>('rig/removeRig', null, {
      params: { planVersionId, objectType, objectIdList: objectIdList.join() },
    });

    return data;
  } catch (e) {
    throwApiError(e);
  }
}
