import { assert } from 'src/shared/utils/assert';
import { addGOplanPrefix } from 'src/shared/utils/prefixes';

import { RawWell } from '../../../types';
import { ComparingWellRigOperation } from '../../comparing-drilling-rigs-chart/entities';

import { ChartRig } from './chart-rig';
import { PadRigOperation } from './pad-rig-operation';
import { WellRigOperation } from './well-rig-operation';

type TCreateOptions = {
  type: WellRigOperationCreator.WellType;
  rawWell: RawWell;
  parentRig: ChartRig;
  parentPad?: PadRigOperation;
};

export namespace WellRigOperationCreator {
  export enum WellType {
    well = 'well',
    comparing = 'comparing',
  }

  export const create = ({ type, rawWell, parentRig, parentPad }: TCreateOptions): WellRigOperation | null => {
    const geoTaskIdFieldName = addGOplanPrefix('GeologicalTask.id');
    const geoTaskIdFieldValue = rawWell[geoTaskIdFieldName];

    assert(typeof geoTaskIdFieldValue === 'number', 'Failed to create chart well.');

    const startFieldName = addGOplanPrefix('RigOperation.workStartDate');
    const startDate = rawWell[startFieldName];
    const startDateNum = Number.parseInt(startDate.toString()) ?? null;

    const endFieldName = addGOplanPrefix('RigOperation.workEndDate');
    const endDate = rawWell[endFieldName];
    const endDateNum = Number.parseInt(endDate.toString()) ?? null;

    if (type === WellType.well) {
      return new WellRigOperation(rawWell, geoTaskIdFieldValue, startDateNum, endDateNum, parentRig, parentPad);
    }

    if (type === WellType.comparing) {
      return new ComparingWellRigOperation(
        rawWell,
        geoTaskIdFieldValue,
        startDateNum,
        endDateNum,
        parentRig,
        parentPad
      );
    }

    return null;
  };
}
