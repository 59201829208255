import { observable, makeObservable } from 'mobx';

import { assert } from 'src/shared/utils/assert';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { TRigOperation } from '../../graph-info-sidebar';
import { InfoSidebarTab } from '../info-sidebar-tab.entity';

import { RigOperation } from './rig-operation.entity';

type TTabWithStagesOptions = {
  fieldId: string;
  directories: Directories;
  notifications: NotificationsStore;
  rigOperations: TRigOperation;
};

export class TabWithStages extends InfoSidebarTab {
  @observable rigOperations: RigOperation[] = [];

  private readonly rigOperationsView: TRigOperation;

  constructor({ fieldId, rigOperations, directories, notifications }: TTabWithStagesOptions) {
    super(fieldId, directories, notifications);
    this.rigOperationsView = rigOperations;

    makeObservable(this);
  }

  private isRigOperations(rawRigOperations: unknown): rawRigOperations is Record<string, unknown>[] {
    if (
      !Array.isArray(rawRigOperations) ||
      rawRigOperations.some((rigOper) => typeof rigOper !== 'object' || rigOper === null)
    ) {
      return false;
    }

    return true;
  }

  setValues(data: Record<string, unknown>): void {
    const rawRigOperations = data[this.rigOperationsView.attrName];

    if (!this.isRigOperations(rawRigOperations)) {
      return;
    }

    rawRigOperations.forEach((rawOper) => {
      const id = rawOper['GOplan_RigOperation.id'];

      assert(
        typeof id === 'number' || (typeof id === 'string' && !Number.isNaN(Number(id))),
        'id is not presented in rig operation'
      );

      const rigOperation = new RigOperation(
        rawOper,
        this.rigOperationsView,
        this.directories,
        this.notifications,
        Number(id)
      );

      this.rigOperations.push(rigOperation);
    });
  }
}
