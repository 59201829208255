import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CompareIcon } from 'src/shared/assets/icons/compare.svg';
import { ReactComponent as SearchIcon } from 'src/shared/assets/icons/search.svg';
import { Input } from 'src/shared/components/input/input';
import { Sidebar } from 'src/shared/components/sidebar/sidebar';
import { Switcher } from 'src/shared/components/switcher/switcher';
import { useStore } from 'src/store/use-store';

import styles from './styles.module.scss';
import { VersionsList } from './versions-list/versions-list';

export const ComparisonSidebar = observer(function ComparisonSidebar() {
  const { t } = useTranslation('drillingCarpet');
  const { comparison } = useStore();

  return (
    <Sidebar
      title={t('Compare.title')}
      isOpened={comparison.isCompareSidebarOpened}
      closeOnClickOutside={false}
      onClose={comparison.closeSidebar}
    >
      <div className={styles.switcherWrapper}>
        <Switcher
          className={styles.overwriteSwitcherBgColor}
          items={[{ text: t('Compare.actual') }, { text: t('Compare.archive') }]}
          isActive={!comparison.isActual}
          isCompact={false}
          onToggle={comparison.toggleActual}
        />
      </div>

      <div className={styles.contentWrapper}>
        <Input
          prefix={<SearchIcon className={styles.icon} />}
          onChange={comparison.searchPlan}
          placeholder={t('common:search')}
        />

        <VersionsList
          onChange={comparison.setPlanForComparison}
          isDisabled={!!comparison.firstTargetPlan && !!comparison.secondTargetPlan}
        />

        <button
          disabled={!comparison.firstTargetPlan || !comparison.secondTargetPlan}
          className={styles.compareButton}
          onClick={comparison.submitComparing}
        >
          <CompareIcon />
          <p className={styles.buttonText}>{t('Compare.compare')}</p>
        </button>
      </div>
    </Sidebar>
  );
});
