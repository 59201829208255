import { Radio } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Well } from 'src/features/unplanned-rig-operations-sidebar/entities/well.entity';
import { hasValue } from 'src/shared/utils/common';

import styles from './well-title.module.scss';

interface Props {
  well: Well;
}

export const WellTitle = observer(function WellTitle({ well }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Radio value={well.id} disabled={!well.id} />
      <p className={styles.title}>{well.title}</p>
      {hasValue(well.rigOperationIndex) && (
        <p className={styles.rigOperation}>
          {t('drawers:unplannedRigOperations.rigOperationIndex', { index: well.rigOperationIndex })}
        </p>
      )}
    </div>
  );
});
