import { observer } from 'mobx-react-lite';

import { WellTypeIcon } from 'src/features/well-type-icon';

import { Well } from '../../entities/tab-with-wells-entities/well.entity';
import { WellColoredCard } from '../well-card/well-colored-card';

import styles from './pad-wells.module.scss';

interface Props {
  wells: Well[];
  wellCardClassName?: string;
  onWellClick?: (wellId: number) => void;
  onWellEditingClick?(wellId: number): void;
  onWellAdd?: VoidFunction;
}

export const PadWells = observer(function PadWells({ wells, wellCardClassName, onWellClick }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {wells.map((well) => (
          <WellColoredCard
            key={well.id}
            text={well.attributes}
            title={well.title}
            color={well.wellColor}
            className={wellCardClassName}
            icons={<WellTypeIcon iconName={well.wellIcon} className={styles.wellIcon} />}
            onClick={() => onWellClick?.(well.id)}
          />
        ))}
      </div>
    </div>
  );
});
