import { makeObservable, observable, flow, computed, action } from 'mobx';

import { BaseApiError } from 'src/errors';
import { hasValue } from 'src/shared/utils/common';
import { createPromiseController, TPromiseController } from 'src/shared/utils/promise-controller';
import { PlanVersionType } from 'src/store/comparison/types';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { DRAFT_ASSIGNMENT } from 'src/store/drafts/types';
import { ENABLE_EDITING_VARIANTS } from 'src/store/editing/types';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PlanVersionStore as PlanVersion } from 'src/store/plan-version';

export class EditingStore {
  @observable isEditing: boolean = false;
  @observable isLoading: boolean = false;
  @observable isEditPublicDraftModalOpened: boolean = false;

  private editingVersionChangeController: TPromiseController<ENABLE_EDITING_VARIANTS> | null = null;
  readonly drafts: DraftsStore;
  private readonly planVersion: PlanVersion;
  private readonly notifications: NotificationsStore;

  constructor(drafts: DraftsStore, planVersion: PlanVersion, notifications: NotificationsStore) {
    this.drafts = drafts;
    this.planVersion = planVersion;
    this.notifications = notifications;

    makeObservable(this);
  }

  @computed
  get actualPlanVersionId(): number | undefined {
    if (this.isEditing && hasValue(this.drafts.draftVersionId)) {
      return this.drafts.draftVersionId;
    }

    return this.planVersion.version?.id;
  }

  @computed
  get isCanEditing(): boolean {
    return (
      !this.planVersion.version?.data.isArchive &&
      this.planVersion.version?.data.versionType !== PlanVersionType.current
    );
  }

  @action.bound
  openEditPublicDraftModal(): void {
    this.isEditPublicDraftModalOpened = true;
  }

  @action.bound
  async enableEditing() {
    try {
      this.editingVersionChangeController = createPromiseController<ENABLE_EDITING_VARIANTS>();

      if (
        !hasValue(this.planVersion.version) ||
        this.planVersion.version.data.versionType === PlanVersionType.current
      ) {
        return;
      }

      if (this.planVersion.version?.data.versionType === PlanVersionType.public) {
        this.openEditPublicDraftModal();
      }

      if (this.planVersion.version?.data.versionType !== PlanVersionType.public) {
        await this.editCurrentVersion();
      }

      return await this.editingVersionChangeController;
    } finally {
      this.editingVersionChangeController = null;
    }
  }

  @action.bound
  restoreEditing(draftVersionId: number, parentVersionId: number | null): void {
    this.drafts.setDraft(draftVersionId, parentVersionId);
    this.isEditing = true;
  }

  @action.bound
  async closeEditPublicDraftModal() {
    if (!this.editingVersionChangeController) return;

    this.isEditPublicDraftModalOpened = false;
    return this.editingVersionChangeController.resolve(ENABLE_EDITING_VARIANTS.cancel);
  }

  @flow.bound
  async *editCurrentVersion() {
    if (!hasValue(this.planVersion.version) || !this.editingVersionChangeController) return;

    try {
      this.isLoading = true;
      await this.drafts.createDraft(this.planVersion.version.id, DRAFT_ASSIGNMENT.editing);
      yield;
      this.isEditing = true;
      this.editingVersionChangeController.resolve(ENABLE_EDITING_VARIANTS.currentVersion);
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToActivateEditMode');
    } finally {
      this.isEditPublicDraftModalOpened = false;
      this.isLoading = false;
    }
  }

  @flow.bound
  async *createPrivateDraft() {
    if (!hasValue(this.planVersion.version) || !this.editingVersionChangeController) return;

    try {
      this.isLoading = true;
      await this.planVersion.createPrivateDraft();

      await this.drafts.createDraft(this.planVersion.version.id, DRAFT_ASSIGNMENT.editing);
      yield;
      this.isEditing = true;
      this.editingVersionChangeController.resolve(ENABLE_EDITING_VARIANTS.privateDraft);
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToActivateEditMode');
    } finally {
      this.isEditPublicDraftModalOpened = false;
      this.isLoading = false;
    }
  }

  @flow.bound
  async *cancelEditing() {
    try {
      await this.drafts.deleteCurrentDraft();
      yield;
      this.isEditing = false;
    } catch (e) {
      yield;
      this.notifications.showErrorMessageT('errors:failedToExitEditMode');
      console.error(e);
    }
  }

  @flow.bound
  async *publishChanges(skipConflicts?: boolean) {
    try {
      if (hasValue(this.drafts.draftVersionId) && !skipConflicts) {
        await this.drafts.conflictResolver.checkConflicts(this.drafts.draftVersionId);
      }

      await this.drafts.publishDraft();
      yield;

      this.isEditing = false;
    } catch (e) {
      yield;

      this.notifications.showErrorMessageT('errors:failedToPublishChanges');
      console.error(e);
    }

    await this.planVersion.reloadVersions();
    yield;
  }

  @flow.bound
  async *saveChanges(skipConflicts?: boolean) {
    try {
      if (hasValue(this.drafts.draftVersionId) && !skipConflicts) {
        await this.drafts.conflictResolver.checkConflicts(this.drafts.draftVersionId);
      }

      await this.drafts.saveDraft();
      yield;

      this.isEditing = false;
    } catch (e) {
      yield;

      this.notifications.showErrorMessageT('errors:failedToSaveChanges');
      console.error(e);
    }
  }
}
