import { DateHelper } from 'src/features/drilling-chart/shared/date-helper';
import { WellTypeIcon } from 'src/shared/constants/well-type-icon';
import { getWellColor } from 'src/shared/utils/get-well-color';
import { AppSettingsStore } from 'src/store/app-settings/app-settings-store';
import { Directories } from 'src/store/directories/directories.store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';

import { WellsItem } from '../../graph-info-sidebar';

export class Well {
  private readonly view: WellsItem;
  private readonly directories: Directories;
  private readonly notifications: NotificationsStore;
  private readonly item: Record<string, unknown>;
  private readonly appSettings: AppSettingsStore;
  readonly id: number;

  constructor(
    view: WellsItem,
    directories: Directories,
    appSettings: AppSettingsStore,
    rawWell: Record<string, unknown>,
    notifications: NotificationsStore,
    id: number
  ) {
    this.item = rawWell;
    this.view = view;
    this.directories = directories;
    this.appSettings = appSettings;
    this.notifications = notifications;
    this.id = id;
  }

  get attributes(): string {
    const attributes = this.view.attributes;

    if (!attributes) {
      return '';
    }

    return attributes
      .reduce<string[]>((stringAttributes, attribute) => {
        const { objectName, type } = attribute;

        const attributeValue = this.item[objectName];

        if (type === 'DateTime') {
          if (typeof attributeValue === 'string') {
            const date = DateHelper.unixToDateFormat(attributeValue);

            if (date) {
              stringAttributes.push(date);
            }
          }
        }

        return stringAttributes;
      }, [])
      .join(' - ');
  }

  get wellColor(): string {
    return getWellColor(this.item, this.appSettings.wellColorRules);
  }

  get wellIcon(): WellTypeIcon | undefined {
    return this.appSettings.getWellIcon(this.item, 'GOplan_GeologicalTask.wellTypeId');
  }

  get title(): string {
    const { attrName, refObjectAttr, refObjectType } = this.view;

    const attrValue = this.item[attrName];

    if (refObjectAttr && refObjectType) {
      const refObject = this.directories.getObject(refObjectType);
      const labelObject = refObject?.find(({ id }) => id.toString() === attrValue);
      const label = labelObject?.data[refObjectAttr];

      return label ? label.toString() : '';
    }

    return attrValue?.toString() || '';
  }
}
