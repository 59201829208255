import { MIN_COLUMN_WIDTH } from 'src/features/wells-list/wells-table/constants';
import { CopyTableCell } from 'src/features/wells-list/wells-table/copy-table-cell';
import { MultiCell } from 'src/features/wells-list/wells-table/multi-cell';
import { TableCell } from 'src/features/wells-list/wells-table/table-cell';
import { WELLS_TYPE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { Checkbox } from 'src/shared/components/checkbox';
import { SelectRowTableCell } from 'src/shared/components/table-cell/select-row-table-cell';
import { hasValue } from 'src/shared/utils/common';
import { AntColumnsType, ColumnType } from 'src/store/table/types';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

export function getColumns(
  columns: ColumnType[],
  wellsType: WELLS_TYPE,
  isEditing: boolean,
  isSelectedAllRows: boolean,
  setIsSelectedAllRows: () => void
): AntColumnsType[] {
  const hasFixedColumns = columns.some((column) => column.fixed);
  const excludeFromSettingsColumns = columns.filter((column) => column.excludeFromSettings);
  const antColumns = [];

  if (isEditing) {
    antColumns.push(
      {
        title: <Checkbox onChange={setIsSelectedAllRows} isChecked={isSelectedAllRows} />,
        dataIndex: 'select',
        key: 'select',
        width: 50,
        ...(columns.some((column) => column.fixed) && { fixed: 'left' }),
      },
      {
        title: '',
        dataIndex: 'copy',
        key: 'copy',
        width: 50,
        ...(hasFixedColumns && { fixed: 'left' }),
      }
    );
  }

  if (wellsType === WELLS_TYPE.drilled) {
    antColumns.push({
      title: '',
      dataIndex: 'copy',
      key: 'copy',
      width: 50,
      ...(hasFixedColumns && { fixed: 'left' }),
    });
  }

  excludeFromSettingsColumns.forEach((column) => {
    antColumns.push({
      title: column.label,
      dataIndex: column.id,
      key: column.id,
      width: column.width,
      ellipsis: true,
      control: column.control,
      isShown: column.isShown,
      objectType: column.objectType,
      objectField: column.objectField,
      attrName: column.attrName,
      refQuery: column.refQuery,
      ...(hasFixedColumns && { fixed: 'left' }),
    });
  });

  columns
    .filter((column) => column.isShown && !column.excludeFromSettings)
    .forEach((column) => {
      antColumns.push({
        title: column.label,
        dataIndex: column.id,
        key: column.id,
        width: column.width,
        ellipsis: true,
        fixed: column.fixed && 'left',
        control: column.control,
        isShown: column.isShown,
        objectType: column.objectType,
        objectField: column.objectField,
        attrName: column.attrName,
        refQuery: column.refQuery,
        ...(hasValue(column.children) && {
          children: [
            ...column?.children
              .filter((childColumn) => childColumn?.isShown)
              .map((childColumn, idx) => {
                return {
                  title: childColumn.label,
                  dataIndex: childColumn.name,
                  key: childColumn.id,
                  control: childColumn.control,
                  width: column.children?.length
                    ? column.width / column.children.filter((childColumn) => childColumn?.isShown).length
                    : MIN_COLUMN_WIDTH,
                  ellipsis: true,
                  fixed: childColumn.fixed && 'left',
                  isShown: childColumn.isShown,
                  objectType: childColumn.objectType,
                  objectField: childColumn.objectField,
                  attrName: childColumn.attrName,
                  refQuery: childColumn.refQuery,
                };
              }),
          ],
        }),
      });
    });

  return antColumns;
}

export function getTableData(
  pageStore: WellsPageStore,
  wellFormManager: WellFormManagerWithGeoTasksHistory
): Record<string, React.ReactNode>[] {
  return pageStore.table.tableData.map((row, index): Record<string, React.ReactNode> => {
    return {
      key: index,
      rowChanged: row.rowChanged,
      select: <SelectRowTableCell handleSelectRow={() => pageStore.table.selectRow(index)} row={row} />,
      copy: <CopyTableCell row={row} onCopyGeologicalTask={wellFormManager.copyGeologicalTask} />,
      ...pageStore.table.columnsData.reduce<Record<string, React.ReactNode>>(function (target, column) {
        target[column.id] = column?.children ? (
          <MultiCell
            row={row}
            childColumns={column?.children}
            pageStore={pageStore}
            wellFormManager={wellFormManager}
          />
        ) : (
          <TableCell
            row={row}
            rowIndex={index}
            column={column}
            pageStore={pageStore}
            wellFormManager={wellFormManager}
          />
        );
        return target;
      }, {}),
    };
  });
}

export function getTableWidth(columns: AntColumnsType[], isSideScrollbar: boolean): string {
  const sumColumnWidth = columns.reduce((sum, currentColumn) => sum + currentColumn.width, 0);
  const padding = 18;

  const tableWidth = sumColumnWidth + padding;

  const scrollBarWidth = isSideScrollbar ? 20 : 0;

  if (window.innerWidth > tableWidth) {
    return `${tableWidth + scrollBarWidth}px`;
  }
  return '100%';
}

export function getTableHeight(
  numberOfRows: number,
  cellHeight: number,
  singleColumnHeaderHeight: number,
  parentColumnHeaderHeight: number,
  childColumnHeaderHeight: number,
  hasHeaderChild: boolean,
  availableSpaceHeight?: number
): number | undefined {
  const scrollbarHeight = 12;

  const headerHeight = hasHeaderChild ? parentColumnHeaderHeight + childColumnHeaderHeight : singleColumnHeaderHeight;

  if (availableSpaceHeight) {
    if (numberOfRows * cellHeight + headerHeight < availableSpaceHeight) {
      return numberOfRows * cellHeight + scrollbarHeight;
    }

    return availableSpaceHeight - (headerHeight + 36);
  }
}

export function hasSideScrollbar(numberOfRows: number, cellHeight: number, tableHeight?: number): boolean {
  if (hasValue(tableHeight)) {
    return tableHeight < cellHeight * numberOfRows;
  }

  return false;
}

export function getLeftColumnOffset(columnIndex: number, columns: AntColumnsType[], fixed?: boolean | string) {
  if (!fixed) {
    return;
  }

  return columns.slice(0, columnIndex).reduce((sum, { width }) => sum + width, 0);
}

export function getLeftChildColumnOffset(
  columnIndex: number,
  childColumnIndex: number,
  columns: AntColumnsType[],
  childColumns?: AntColumnsType[],
  fixed?: boolean | string
) {
  if (!fixed || !childColumns) return;

  const parentOffset = columns.slice(0, columnIndex).reduce((sum, { width }) => sum + width, 0);
  const childOffset = childColumns.slice(0, childColumnIndex).reduce((sum, { width }) => sum + width, 0);

  return parentOffset + childOffset;
}

export function getMinColumnWidth(column: AntColumnsType): number {
  if (column?.children) {
    return (
      column.children.reduce((prev, curr) => {
        return curr?.isShown ? prev + 1 : prev;
      }, 0) * MIN_COLUMN_WIDTH
    );
  }

  return MIN_COLUMN_WIDTH;
}
