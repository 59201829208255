import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store';

import { InfoSidebarTab } from '../entities/info-sidebar-tab.entity';
import { TabWithColumns } from '../entities/tab-with-columns-entities/tab-with-columns.entity';
import { TabWithPads } from '../entities/tab-with-pads-entities/tab-with-pads.entity';
import { TabWithStages } from '../entities/tab-with-stages-entities/tab-with-stages.entity';
import { TabWithWells } from '../entities/tab-with-wells-entities/tab-with-wells.entity';

import { BasicData } from './basic-data';
import { PadWells } from './pad-wells/pad-wells';
import { Pads } from './pads';
import { WellStages } from './well-stages';

import styles from './info-sidebar-tab-component.module.scss';

type Props = {
  activeTab: InfoSidebarTab | undefined | null;
  onWellClick?: (wellId: number) => void;
  onPadClick?(padId: number): void;
  onWellAdd?: VoidFunction;
  onEditingWellClick?: (wellId: number) => void;
};

export const InfoSidebarTabContent = observer(function InfoSidebarTabContent({
  activeTab,
  onWellClick,
  onPadClick,
  onWellAdd,
  onEditingWellClick,
}: Props) {
  const { editing } = useStore();

  if (activeTab instanceof TabWithColumns && !!activeTab.columns.length) {
    return <BasicData columns={activeTab.columns} />;
  }
  if (activeTab instanceof TabWithStages && !!activeTab.rigOperations.length) {
    return <WellStages rigOperations={activeTab.rigOperations} />;
  }
  if (activeTab instanceof TabWithWells && !!activeTab.wells.length) {
    return (
      <div className={styles.TabWithWellsPadWellsWrapper}>
        <PadWells wells={activeTab.wells} wellCardClassName={styles.wellCard} onWellClick={onWellClick} />
      </div>
    );
  }
  if (activeTab instanceof TabWithPads && !!activeTab.pads.length) {
    return (
      <Pads
        pads={activeTab.pads}
        isEditing={editing.isEditing}
        onWellClick={onWellClick}
        onPadClick={onPadClick}
        onWellAdd={onWellAdd}
        onEditingWellClick={onEditingWellClick}
      />
    );
  }

  return null;
});
