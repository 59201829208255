import { agent } from 'src/api/agent';
import { throwApiError } from 'src/api/utils';
import { PlanVersionObjectType } from 'src/api/version/types';

export async function createDraft(planVersionId: number, formName: string): Promise<number> {
  try {
    const { data } = await agent.post<PlanVersionObjectType>(
      `version/tempDraft/copyVersion/${planVersionId}/${formName}`
    );

    return data.id;
  } catch (e) {
    throwApiError(e);
  }
}

export async function deleteCarpetVersion(planVersionId: number): Promise<void> {
  try {
    await agent.delete(`version/${planVersionId}`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function publishVersion(planVersionId: number): Promise<void> {
  try {
    await agent.post(`version/${planVersionId}/publish`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function saveToPublicDraft(planVersionId: number): Promise<void> {
  try {
    await agent.post(`version/${planVersionId}/saveToPublicDraft`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function saveVersion(sourcePlanVersionId: number, targetPlanVersionId: number): Promise<void> {
  try {
    await agent.post(`version/${sourcePlanVersionId}/saveTo/${targetPlanVersionId}`);
  } catch (e) {
    throwApiError(e);
  }
}

export async function createPrivateDraft(planVersionId: number): Promise<number> {
  try {
    const { data } = await agent.post(`version/privateDraft/copyVersion/${planVersionId}`);

    return data.id;
  } catch (e) {
    throwApiError(e);
  }
}

export async function toggleVersionForAnalyticsRequest(planVersionId: number, isChosen: boolean): Promise<void> {
  try {
    await agent.post(
      `version/${planVersionId}/dashboardLite`,
      {},
      {
        params: {
          availableForDashboardLite: isChosen,
        },
      }
    );
  } catch (e) {
    throwApiError(e);
  }
}
