import { makeObservable, action, observable, flow, reaction, computed, when } from 'mobx';

import { UserSettingsManager } from 'src/api/user-settings';
import {
  getWellsList,
  WellsListViewType,
  getCompareWellsList,
  saveWellsChanges,
  IndexedWellType,
  UserSettingsType,
  multiplySaveWellsChanges,
  OBJECT_TYPE,
  removeRig,
  WellsListResponseType,
  CompareWellsListResponseType,
} from 'src/api/wells-list';
import { BaseApiError } from 'src/errors';
import { MakeWellIsPlannedPlugin } from 'src/features/well-form/plugins/make-well-is-planned-validation.plugin';
import { WELLS_LIST_LOAD_LIMIT } from 'src/pages/wells-page/constants';
import {
  UserSettingsColumnsGroupType,
  WELLS_TYPE,
  SortingOptionType,
  FilterType,
  PAGE_MODE,
} from 'src/pages/wells-page/types';
import {
  serializeUserSettingsData,
  getObjectsNames,
  mapTabsData,
  serializeSortingObject,
  serializeFiltersObject,
  getWellsChanges,
  getUseInMainProgressBarAttrNames,
  getRemovableObjectsIdList,
  getJoins,
  showEditNotification,
} from 'src/pages/wells-page/utils';
import { hasValue, omit } from 'src/shared/utils/common';
import { RootStore } from 'src/store';
import { ComparisonStore } from 'src/store/comparison/comparison-store';
import { Directories } from 'src/store/directories/directories.store';
import { DraftsStore } from 'src/store/drafts/drafts-store';
import { EditingStore } from 'src/store/editing/editing-store';
import { JobsStore } from 'src/store/jobs/jobs-store';
import { NotificationsStore } from 'src/store/notifications-store/notifications-store';
import { PlanVersionStore as PlanVersion } from 'src/store/plan-version';
import { TableStore as Table } from 'src/store/table/';
import { ColumnType, CellValueType } from 'src/store/table/types';
import { serializeTableData } from 'src/store/table/utils';
import { ViewsStore } from 'src/store/views';
import { WellFormManagerWithGeoTasksHistory } from 'src/store/well-form-manager/well-form-manager-with-geo-tasks-history';

export class WellsPageStore {
  private readonly settingsManager: UserSettingsManager<UserSettingsType>;
  private readonly jobListStore: JobsStore;

  @observable isLoading: boolean = false;
  @observable isActionLoading: boolean = false;
  @observable isMoveToUnplannedModalOpened: boolean = false;
  @observable isMoveToPlannedModalOpened: boolean = false;
  @observable isConfirmCancelEditingModalOpened: boolean = false;
  @observable isHistorySidebarOpened: boolean = false;
  @observable isViewSettingsSidebarOpened: boolean = false;
  @observable isBlankFieldSidebar: boolean = false;
  @observable isWellInfoSidebarOpen: boolean = false;
  @observable isSaveUpdateLoading: boolean = false;
  @observable view: WellsListViewType | null = null;
  @observable tab: string | null = null;
  @observable sorting: SortingOptionType | null = null;
  @observable filters: FilterType[] = [];
  @observable wellsType: WELLS_TYPE = WELLS_TYPE.planned;
  @observable selectedWellId: number | string | null = null;
  @observable availableSpaceHeight?: number;
  @observable tabs?: Record<string, ColumnType[]>;
  @observable progressAttrNames: string[] = [];
  @observable totalWellsNumber: number | null = null;

  readonly draftStore: DraftsStore;
  readonly notifications: NotificationsStore;
  readonly comparison: ComparisonStore;
  readonly directories: Directories;
  readonly table: Table;
  readonly planVersion: PlanVersion;
  readonly editing: EditingStore;
  readonly wellFormManager: WellFormManagerWithGeoTasksHistory;
  readonly views: ViewsStore;

  constructor(store: RootStore) {
    this.settingsManager = new UserSettingsManager<UserSettingsType>('wells', {});
    this.directories = store.directories;
    this.planVersion = store.planVersion;
    this.comparison = store.comparison;
    this.editing = store.editing;
    this.notifications = store.notifications;
    this.draftStore = store.drafts;
    this.views = store.views;
    this.jobListStore = store.jobsStore;
    this.wellFormManager = new WellFormManagerWithGeoTasksHistory(store, [new MakeWellIsPlannedPlugin(store)]);
    this.table = new Table();

    makeObservable(this);
  }

  @action.bound
  pageEffect() {
    this.fetchWellsData();

    const disposePlanVersionId = reaction(() => this.currentVersionId, this.fetchWellsList);
    const disposeComparing = reaction(() => this.comparison.isComparing, this.fetchWellsList);
    const disposeEditing = reaction(
      () => this.mode,
      (mode: PAGE_MODE) => {
        if (mode === PAGE_MODE.edit && this.wellsType === WELLS_TYPE.drilled) {
          this.setWellsType(WELLS_TYPE.planned);
        }
      }
    );

    return () => {
      disposePlanVersionId();
      disposeComparing();
      disposeEditing();
    };
  }

  @computed
  get mode(): PAGE_MODE {
    if (this.editing.isEditing) {
      return PAGE_MODE.edit;
    }

    if (this.comparison.isComparing) {
      return PAGE_MODE.compare;
    }

    return PAGE_MODE.view;
  }

  @computed
  get currentVersionId(): number | null {
    if (this.mode === PAGE_MODE.edit && hasValue(this.editing.drafts.draftVersionId)) {
      return this.editing.drafts.draftVersionId;
    }

    return this.planVersion.version?.id || null;
  }

  @computed
  get widthRigOperation(): boolean {
    return this.view?.tabs.find((tab) => tab.fieldId === this.tab)?.withRigOperation || false;
  }

  @computed
  get removableObjectType(): OBJECT_TYPE {
    return this.widthRigOperation ? OBJECT_TYPE.rigOperation : OBJECT_TYPE.geologicalTask;
  }

  @computed
  get isCanEditing(): boolean {
    return this.editing.isCanEditing && this.wellsType !== WELLS_TYPE.drilled;
  }

  @computed
  get isCanLoadMore(): boolean {
    if (!this.totalWellsNumber) {
      return false;
    }

    return this.totalWellsNumber - this.table.tableData.length > 0;
  }

  @computed
  get wellsListOffset(): number {
    return this.table.tableData.length;
  }

  @action.bound
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
    this.table.setLoading(isLoading);
  }

  @action.bound
  setIsActionLoading(isLoading: boolean): void {
    this.isActionLoading = isLoading;
  }

  @action.bound
  setAvailableSpaceHeight(height: number): void {
    this.availableSpaceHeight = height;
  }

  @action.bound
  setIsViewSettingsSidebarOpened(isOpened: boolean): void {
    this.isViewSettingsSidebarOpened = isOpened;
  }

  @action.bound
  setIsMoveToUnplannedModalOpened(isOpened: boolean): void {
    this.isMoveToUnplannedModalOpened = isOpened;
  }

  @action.bound
  setIsMoveToPlannedModalOpened(isOpened: boolean): void {
    this.isMoveToPlannedModalOpened = isOpened;
  }

  @action.bound
  setIsConfirmCancelEditingModalOpened(isOpened: boolean): void {
    this.isConfirmCancelEditingModalOpened = isOpened;
  }

  @action.bound
  setIsHistorySidebarOpened(isOpened: boolean): void {
    this.isHistorySidebarOpened = isOpened;
  }

  @action.bound
  openBlankFieldSidebar(): void {
    this.isBlankFieldSidebar = true;
  }

  @action.bound
  closeBlankFieldSidebar(): void {
    this.isBlankFieldSidebar = false;
  }

  @action.bound
  setSorting(sortingObj: SortingOptionType | null): void {
    this.sorting = sortingObj;

    this.fetchWellsList();
  }

  @action.bound
  setFilters(filters: FilterType[]): void {
    this.filters = filters;

    this.fetchWellsList();
  }

  @action.bound
  setColumnsSettings(columnsData: ColumnType[]): void {
    this.table.setColumnsData(columnsData);
    this.updateUserSettings(serializeUserSettingsData(columnsData));
  }

  @action.bound
  setWellsType(wellsType: WELLS_TYPE) {
    this.wellsType = wellsType;
    this.fetchWellsList();
  }

  @action.bound
  closeWellInfoSidebar(): void {
    this.selectedWellId = null;
    this.isWellInfoSidebarOpen = false;
  }

  @action.bound
  openWellInfoSidebar(wellId: number | string): void {
    this.selectedWellId = wellId;
    this.isWellInfoSidebarOpen = true;
  }

  @flow.bound
  async *moveToUnplannedWells() {
    if (!this.currentVersionId) return;
    this.setIsLoading(true);
    try {
      const selectedRows = this.table.selectedRows;
      const objectType = this.removableObjectType;
      const objectIdList = getRemovableObjectsIdList(selectedRows, objectType);

      await removeRig(this.currentVersionId, objectType, objectIdList);
      await this.fetchWellsList();
    } catch (error) {
      yield;
      console.error(error);
      if (error instanceof BaseApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToCopyWell');
    } finally {
      this.setIsLoading(false);
      this.setIsMoveToUnplannedModalOpened(false);
    }
  }

  @flow.bound
  async *setTab(tab: string) {
    this.tab = tab;
    this.sorting = null;
    this.filters = [];

    if (this?.tabs && this?.tabs[tab]) {
      await this.fetchWellsData();
      yield;

      this.table.setColumnsData(this.tabs[tab]);
    }
  }

  @flow.bound
  async *editTableData(
    currentRowIndex: number,
    attrName: string,
    newValue: CellValueType,
    column: ColumnType | Omit<ColumnType, 'width'>,
    isMassEditable?: boolean
  ) {
    if (!this.currentVersionId) return;
    this.setIsLoading(true);

    try {
      // Одиночное редактирование
      if (!isMassEditable || this.table.selectedRowsNumber === 0) {
        const wellsChanges = getWellsChanges(this.table.tableData[currentRowIndex], newValue, column, this.directories);

        if (!wellsChanges) return;

        const wellsChangesResponse = await saveWellsChanges(this.currentVersionId, wellsChanges);
        yield;

        //TODO Впоследствии сделать проверку на наличе конфликтов
        const newObjects = wellsChangesResponse.idList;

        newObjects.forEach((object) => (wellsChanges[`${object.objectType}.id`] = object.id));

        const newTableData = serializeTableData(this.table.tableData, this.mode).map((row, index) => {
          return index === currentRowIndex ? { ...row, ...wellsChanges } : row;
        });

        this.table.setTableData(newTableData);

        if (!isMassEditable && this.table.selectedRowsNumber > 0) {
          showEditNotification(this.notifications, this.table.selectedRowsNumber, 1);
        }
      }

      // Массовое редактирование
      if (isMassEditable && this.table.selectedRowsNumber > 0) {
        const changesRows = this.table.tableData.reduce<IndexedWellType[]>((target, row, index) => {
          if (row.selected || index === currentRowIndex) {
            const wellsChanges = getWellsChanges(row, newValue, column, this.directories);
            wellsChanges && target.push({ index, ...wellsChanges });
          }

          return target;
        }, []);

        const indexedNewWellsObjects = await multiplySaveWellsChanges(this.currentVersionId, changesRows);
        yield;

        //TODO Впоследствии сделать проверку на наличе конфликтов

        const newTableData = serializeTableData(this.table.tableData, this.mode).map((row, rowIndex) => {
          if (row.selected || rowIndex === currentRowIndex) {
            // newWellsObjects нужен для того, чтобы в newTableData попали новые значения ID с сервера
            const newWellsObjects = indexedNewWellsObjects
              .find((well) => well.index === rowIndex)
              ?.objects.reduce<Record<string, number>>((target, object) => {
                target[`${object.objectType}.id`] = object.id;
                return target;
              }, {});

            // Вместо wellsChanges взять просто newValue не получится, т.к. когда появятся формулы и зависимые поля внутри функции getWellsChanges() может измениться ни одно значение, а несколько
            const wellsChanges = changesRows.find((well) => well.index === rowIndex);

            return {
              ...row,
              ...newWellsObjects,
              ...(wellsChanges && omit(wellsChanges, 'index')),
            };
          }

          return row;
        });

        this.table.setTableData(newTableData);

        showEditNotification(this.notifications, this.table.selectedRowsNumber, changesRows.length);
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('common:unknownError');
    } finally {
      this.setIsLoading(false);
    }
  }

  @flow.bound
  async *fetchWellsData() {
    this.setIsLoading(true);

    try {
      if (!this.currentVersionId) {
        return;
      }

      if (!this.view) {
        const view = await this.views.wellListView.loadView();
        yield;
        this.view = view;
      }

      if (!this.tab) {
        this.tab = this.view.tabs[0].fieldId;
      }

      const usersSettings = await this.settingsManager.getOrCreate();
      yield;

      await Promise.all([
        this.directories.loadObjects(getObjectsNames(this.view)),
        this.directories.loadJoinedObjectsDeprecated(getJoins(this.view)),
      ]);
      yield;

      this.tabs = mapTabsData(this.view, this.directories, usersSettings);
      this.table.setColumnsData(this.tabs[this.tab]);
      this.progressAttrNames = getUseInMainProgressBarAttrNames(this.view);

      await this.fetchWellsList();
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToBuildTable');
      return;
    } finally {
      this.setIsLoading(false);
    }
  }

  @flow.bound
  async *loadMoreWells() {
    this.setIsLoading(true);

    try {
      const offset = this.table.tableData.length;

      const wellsList = await this.getWellsList(offset);
      yield;

      if (wellsList) {
        this.totalWellsNumber = wellsList.total;
        this.table.addTableData(wellsList.wells);
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToLoadData');
      return;
    } finally {
      this.setIsLoading(false);
    }
  }

  @flow.bound
  async *fetchWellsList() {
    try {
      this.setIsLoading(true);
      const offset = 0;

      const wellsList = await this.getWellsList(offset);
      yield;

      if (wellsList) {
        this.totalWellsNumber = wellsList.total;
        this.table.setTableData(wellsList.wells);
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }
      this.notifications.showErrorMessageT('errors:failedToLoadData');
      return;
    } finally {
      this.setIsLoading(false);
    }
  }

  private async getWellsList(
    offset: number,
    limit?: number
  ): Promise<CompareWellsListResponseType | WellsListResponseType | null> {
    const isComparing = this.mode === PAGE_MODE.compare;
    const firstVersionId = this.comparison.firstTargetPlan?.id;
    const secondVersionId = this.comparison.secondTargetPlan?.id;

    const sortingObject = serializeSortingObject(this.view, this.sorting);
    const filters = serializeFiltersObject(this.view, this.tab, this.filters);
    const wellsListLimit = limit ?? WELLS_LIST_LOAD_LIMIT;

    if (isComparing && hasValue(firstVersionId) && hasValue(secondVersionId)) {
      return await getCompareWellsList(
        firstVersionId,
        secondVersionId,
        this.wellsType,
        offset,
        wellsListLimit,
        this.widthRigOperation,
        sortingObject,
        filters
      );
    }

    if (!isComparing && hasValue(this.currentVersionId)) {
      return await getWellsList(
        this.wellsType,
        this.currentVersionId,
        offset,
        wellsListLimit,
        this.widthRigOperation,
        sortingObject,
        filters
      );
    }

    return null;
  }

  @flow.bound
  async *updateUserSettings(userSettings: UserSettingsColumnsGroupType[]) {
    if (!this.tab) {
      return;
    }

    try {
      await this.settingsManager.update({
        [this.tab]: userSettings,
      });
      yield;

      this.notifications.showSuccessMessageT('notifications:successSavedSettings');
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
    }
  }

  @flow.bound
  async *enableEditing() {
    if (this.jobListStore.hasActiveImportJob) return;

    this.setIsLoading(true);
    try {
      await this.editing.enableEditing();
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
    } finally {
      this.setIsLoading(false);
    }
  }

  @flow.bound
  async *publishChanges() {
    if (this.table.isValidationError) {
      this.notifications.showErrorMessageT('errors:hasErrors');
      this.setIsActionLoading(false);
      return;
    }
    try {
      await when(() => this.isLoading === false);
      yield;

      await this.editing.publishChanges();
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
    } finally {
      this.setIsActionLoading(false);
    }
  }

  @flow.bound
  async *cancelEditing() {
    try {
      await when(() => this.isLoading === false);
      yield;

      await this.editing.cancelEditing();
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
    } finally {
      this.setIsActionLoading(false);
    }
  }

  @flow.bound
  async *saveChanges() {
    if (this.table.isValidationError) {
      this.notifications.showErrorMessageT('errors:hasErrors');
      this.setIsActionLoading(false);
      return;
    }

    try {
      await when(() => this.isLoading === false);
      yield;

      await this.editing.saveChanges();
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('common:unknownError');
    } finally {
      this.setIsActionLoading(false);
    }
  }

  @flow.bound
  async *reloadTableData() {
    this.setIsLoading(true);

    const offset = 0;
    const limit = this.table.tableData.length;

    try {
      const wellsList = await this.getWellsList(offset, limit);
      yield;

      if (wellsList) {
        this.totalWellsNumber = wellsList.total;
        this.table.setTableData(wellsList.wells);
      }
    } catch (e) {
      yield;
      console.error(e);
      if (e instanceof BaseApiError && e.responseMessage) {
        this.notifications.showErrorMessage(e.responseMessage);
        return;
      }

      this.notifications.showErrorMessageT('errors:failedToLoadData');
    } finally {
      this.selectedWellId = null;
      this.setIsLoading(false);
    }
  }

  @action.bound
  async onFormClose() {
    await this.reloadTableData();
    this.wellFormManager.setIsFormOpen(false);
    this.wellFormManager.resetFormManager();
  }
}
