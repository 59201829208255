import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { MoveToPlannedModal } from 'src/features/modals/move-to-planned-modal';
import { MoveToUnplannedModal } from 'src/features/modals/move-to-unplanned-modal';
import { WellsTabs } from 'src/features/wells-list/wells-tabs';
import { WELLS_TYPE } from 'src/pages/wells-page/types';
import { WellsPageStore } from 'src/pages/wells-page/wells-page.store';
import { Button } from 'src/shared/components/button';
import { hasValue } from 'src/shared/utils/common';
import { getCellValue } from 'src/store/table/utils';

import styles from './edit-bar.module.scss';

interface Props {
  pageStore: WellsPageStore;
  makeWellIsPlanned(id: number | string): void;
}

export const EditBar = observer(function EditBar({ pageStore, makeWellIsPlanned }: Props) {
  const { t } = useTranslation('wells');

  const selectedRowsNumber = pageStore.table.selectedRowsNumber;

  function onOpenMoveToUnplannedModal(): void {
    pageStore.setIsMoveToUnplannedModalOpened(true);
  }

  function onCloseMoveToUnplannedModal(): void {
    pageStore.setIsMoveToUnplannedModalOpened(false);
  }

  function onConfirmMoveToUnplannedModal(): void {
    pageStore.moveToUnplannedWells();
  }

  function onOpenMoveToPlannedModal(): void {
    pageStore.setIsMoveToPlannedModalOpened(true);
  }

  function onCloseMoveToPlannedModal(): void {
    pageStore.setIsMoveToPlannedModalOpened(false);
  }

  function onConfirmMoveToPlannedModal(): void {
    const selectedRows = pageStore.table.selectedRows;

    if (selectedRows.length === 1) {
      // Hardcode-attr
      const wellId = getCellValue(selectedRows[0]['GOplan_GeologicalTask.id']);

      if (!hasValue(wellId) || (typeof wellId !== 'number' && typeof wellId !== 'string')) {
        return;
      }

      makeWellIsPlanned(wellId);
    }

    pageStore.setIsMoveToPlannedModalOpened(false);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <WellsTabs className={styles.categorySwitcher} pageStore={pageStore} />

        {pageStore.wellsType === WELLS_TYPE.planned && selectedRowsNumber > 0 && (
          <Button className={styles.button} variant="primary" onClick={onOpenMoveToUnplannedModal}>
            {t('wells:moveToUnplanned')}
          </Button>
        )}

        {pageStore.wellsType === WELLS_TYPE.unplanned && selectedRowsNumber > 0 && (
          <Button className={styles.button} variant="primary" onClick={onOpenMoveToPlannedModal}>
            {t('wells:moveToPlanned')}
          </Button>
        )}
      </div>

      <MoveToUnplannedModal
        isOpened={pageStore.isMoveToUnplannedModalOpened}
        onConfirm={onConfirmMoveToUnplannedModal}
        onCancel={onCloseMoveToUnplannedModal}
      />

      <MoveToPlannedModal
        isOpened={pageStore.isMoveToPlannedModalOpened}
        onConfirm={onConfirmMoveToPlannedModal}
        onCancel={onCloseMoveToPlannedModal}
        isWarning={pageStore.table.selectedRowsNumber > 1}
      />
    </>
  );
});
